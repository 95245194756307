/* eslint-disable no-useless-catch */
import api from "@/api/public/index";

export default {
  state: {
    searchData: [],
  },
  mutations: {
    setSearchResults(state, payload) {
      state.searchData = payload;
    },
    setSearchDataDefault(state) {
      state.searchData = [];
    },
  },
  actions: {
    async getItemsBySearchQuery({ commit }, payload) {
      try {
        const searchData = (
          await api.search.getItemsBySearchQuery({
            value: payload,
          })
        ).data;

        commit("setSearchResults", searchData);
      } catch (e) {
        throw e;
      }
    },
    clearSearchData({ commit }) {
      commit("setSearchDataDefault");
    },
  },
  getters: {},
};
