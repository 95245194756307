<template>
  <Accordion :activeIndex="activeTab">
    <AccordionTab v-for="(item, index) in items" :key="item.id">
      <template #header>
        <a
          v-if="isLink(item)"
          class="block p-accordion-header-wrapper flex justify-content-between w-full"
          :href="item?.url"
        >
          <div class="p-accordion-header-text">
            <span>
              {{
                `${searchQuery ? item?.departaments?.title + " / " : ""}${
                  item?.title
                }`
              }}
            </span>
          </div>
          <div class="p-accordion-header-icon flex align-items-center">
            <img class="arrow-link" src="@/assets/img/content/arrow.svg" />
          </div>
        </a>
        <div
          v-else
          class="p-accordion-header-wrapper flex justify-content-between w-full"
          data-index="88"
          @click="createAccordionCurrentScroll(index)"
        >
          <div class="p-accordion-header-text">
            <span>
              {{
                `${
                  searchQuery && item?.departaments.title
                    ? item?.departaments?.title + " / "
                    : ""
                }${item?.title}`
              }}
            </span>
          </div>
          <div class="p-accordion-header-icon flex align-items-center">
            <img src="@/assets/img/content/arrow.svg" />
          </div>
        </div>
      </template>
      <div
        v-if="item?.text"
        v-html="
          searchQuery && item?.text ? showSearchedText(item?.text) : item?.text
        "
        class="accordion-content"
      ></div>
    </AccordionTab>
  </Accordion>
</template>

<script>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

export default {
  components: {
    Accordion,
    AccordionTab,
  },
  props: {
    items: Array,
    searchQuery: String,
  },
  data() {
    return {
      activeTab: null,
    };
  },
  methods: {
    createAccordionCurrentScroll(index) {
      let wrapperPadding = parseInt(
        window.getComputedStyle(
          document.getElementsByClassName(
            this.searchQuery ? "search-results-section" : "department-services"
          )[0]
        ).paddingTop
      );

      let headerHeight = this.searchQuery
        ? document.getElementsByClassName(
            "search-results-section-header-wrapper"
          )[0].clientHeight
        : document.getElementsByTagName("header")[0].clientHeight;

      let currentPos = [...document.getElementsByClassName("p-accordion-tab")]
        .slice(0, index)
        .reduce((sum, el) => {
          return sum + el.childNodes[0].clientHeight;
        }, headerHeight + wrapperPadding);

      window.scrollTo({
        top: currentPos,
        behavior: "smooth",
      });
    },
    isLink(item) {
      return item?.url && item?.url !== "" && item?.text === "";
    },
    goToURL(e) {
      let item = this.items[e.index];

      if (this.isLink(item)) {
        window.open(item?.url, "_self");
      }
    },
    showSearchedText(text) {
      let searchWords = this.searchQuery.trim().split(" ");

      return searchWords.reduce((temp, word) => {
        if (word) {
          let regex = new RegExp(word, "gi");

          return (temp = temp.replace(
            regex,
            `<span class="search-word">${
              temp.match(regex) ? temp.match(regex)[0] : ""
            }</span>`
          ));
        } else {
          return temp;
        }
      }, text);
    },
  },
  updated() {
    // in order to automatically open the tab, it is necessary to transfer its serial number (not the index) in the link: url?tab=3

    let activeTab = this.$route.query.tab;
    if (activeTab) {
      this.activeTab = parseInt(activeTab) - 1;
      this.createAccordionCurrentScroll(this.activeTab);
    }
  },
};
</script>
