import { showErrorNotify } from "@/helpers/notify";

export default (instance) => {
  return {
    getPostsDataByType(payload) {
      return instance
        .post(`api/posts/all`, payload)
        .catch((error) => showErrorNotify(error));
    },
    getPostTypesData() {
      return instance
        .get(`api/post_types`)
        .catch((error) => showErrorNotify(error));
    },
  };
};
