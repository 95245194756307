<template>
  <div
    class="visual-impairment-control flex justify-content-center align-items-center"
  >
    <button
      class="visual-impairment-btn"
      @click="toggleEnableVisualImpairment()"
    >
      <span class="visual-impairment-btn-text">
        {{
          enableVisualImpairment
            ? "Стандартна версія"
            : "Людям із порушенням зору"
        }}
      </span>
    </button>
    <div v-show="enableVisualImpairment" class="font-size-control">
      <div class="flex align-items-center">
        <button
          :disabled="fontSettingStep.fontSize === MAX_FONT_SIZE_STEP"
          @click="
            changeFontSizeProcess(INCREMENT_PROCESS), fontSettingStep.fontSize++
          "
        >
          А+
        </button>
        <button
          :disabled="fontSettingStep.fontSize === MIN_FONT_SIZE_STEP"
          @click="
            changeFontSizeProcess(DECREMENT_PROCESS), fontSettingStep.fontSize--
          "
        >
          А-
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const VISUAL_IMPAIRMENT_FILTER_CLASS = "visual-impairment-filter";

export default {
  data() {
    return {
      enableVisualImpairment: false,
      body: document.getElementsByTagName("body")[0],
      fontSettingStep: {
        fontSize: 0,
        lineHeight: 0,
      },
      MAX_FONT_SIZE_STEP: 5,
      MIN_FONT_SIZE_STEP: -5,
      INCREMENT_PROCESS: "increment",
      DECREMENT_PROCESS: "decrement",
      STYLE_PROPERTIES: {
        fontSize: {
          name: "font-size",
          value: 1,
        },
        lineHeight: {
          name: "line-height",
          value: 2,
        },
      },
      elementsList: null,
    };
  },
  methods: {
    toggleEnableVisualImpairment() {
      this.enableVisualImpairment = !this.enableVisualImpairment;

      if (this.enableVisualImpairment) {
        this.body.classList.add(VISUAL_IMPAIRMENT_FILTER_CLASS);
      } else {
        this.body.classList.remove(VISUAL_IMPAIRMENT_FILTER_CLASS);
        this.fontSettingStep.fontSize = 0;
        this.fontSettingStep.lineHeight = 0;

        this.changeFontSizeProcess(null);
      }
    },
    changeFontSizeInElement(type, el, property, value) {
      return type === this.INCREMENT_PROCESS
        ? parseFloat(
            this.getStylePropertyValue(el, this.STYLE_PROPERTIES[property].name)
          ) +
            value +
            "px"
        : parseFloat(
            this.getStylePropertyValue(el, this.STYLE_PROPERTIES[property].name)
          ) -
            value +
            "px";
    },
    changeFontSizeProcess(type) {
      for (let el of document.body.getElementsByTagName("*")) {
        if (type !== null) {
          for (let property in this.STYLE_PROPERTIES) {
            el.style[property] = this.changeFontSizeInElement(
              type,
              el,
              property,
              this.STYLE_PROPERTIES[property].value
            );
          }
        } else {
          el.style.fontSize = "";
          el.style.lineHeight = "";
        }
      }
    },
    getStylePropertyValue(el, property) {
      return window.getComputedStyle(el, null).getPropertyValue(property);
    },
  },
  mounted() {
    this.enableVisualImpairment = this.body.classList.value
      .split(" ")
      .some((el) => el === VISUAL_IMPAIRMENT_FILTER_CLASS);
  },
};
</script>
