<template>
  <div
    class="h-screen h-full auth-wrapper flex justify-content-center align-items-center p-fluid"
  >
    <div class="back-auth-form content text-center">
      <h2>{{ department?.title ? department.title : "Панель керування" }}</h2>

      <AuthForm />
    </div>
  </div>
</template>

<script>
import AuthForm from "@/components/admin/auth/AuthForm.vue";

import filters from "@/config/filters.yml";

import { mapGetters, mapActions, mapState } from "vuex";
import { createFilters } from "@/helpers/filters";

export default {
  name: "ManagerAuthView",
  components: {
    AuthForm,
  },
  data() {
    return {
      filterByID: {
        field: filters.departaments.filterByID,
        value: null,
      },
      filters: {
        pageSize: -1,
      },
    };
  },
  computed: {
    ...mapState({
      department: (state) => state.public_departments.department,
    }),
    ...mapGetters(["departmentByField", "isAdmin", "adminTokenIsValid"]),
  },
  methods: {
    ...mapActions(["getPublicDepartmentData", "clearPublicDepartmentsData"]),
  },
  async mounted() {
    if (this.adminTokenIsValid) {
      this.$route.meta.isAdmin && this.isAdmin
        ? this.$router.push({ name: "admin-posts" })
        : this.$router.push({ name: "posts" });
    }

    if (!this.$route.meta.isAdmin) {
      try {
        this.filterByID.value = this.$route.meta.departmentID;

        await this.getPublicDepartmentData(
          createFilters(this.filters, [this.filterByID])
        );
      } catch (error) {
        console.log(error);
      }
    }
  },
  unmounted() {
    this.clearPublicDepartmentsData();
  },
};
</script>
