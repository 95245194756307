<template>
  <UINavBar />

  <main>
    <section class="content-section search-results-section">
      <div class="p-container">
        <div
          v-if="$route.query.search"
          class="search-results-section-header-wrapper"
        >
          <h1 class="section-header m-0">Результати пошуку</h1>
          <p class="search-results-description">
            {{
              `Ви шукали «${$route.query.search}». ${
                searchData.data
                  ? "Знайдено наступні публікації:"
                  : "Нічого не знайдено."
              }`
            }}
          </p>
        </div>
        <div v-else class="search-results-section-header-wrapper">
          <h1 class="section-header m-0">Введіть пошуковий запит</h1>
          <div class="w-full mt-3">
            <UISearchForm />
          </div>
        </div>
        <hr v-if="$route.query.search" class="search-results-divider" />
        <AccordionPanel
          v-if="searchData.data"
          class="search-results"
          :items="searchData.data"
          :searchQuery="$route.query.search"
        />
      </div>
    </section>
  </main>
  <FooterSection />
</template>

<script>
import FooterSection from "@/components/public/content/FooterSection.vue";
import UINavBar from "@/components/public/ui/UINavBar.vue";
import UISearchForm from "@/components/public/ui/UISearchForm.vue";
import AccordionPanel from "@/components/public/content/AccordionPanel.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "AllNewsView",
  components: {
    UINavBar,
    FooterSection,
    UISearchForm,
    AccordionPanel,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      searchData: (state) => state.public_search.searchData,
    }),
  },
  methods: {
    ...mapActions(["getItemsBySearchQuery", "clearSearchData"]),

    async searching() {
      try {
        await this.getItemsBySearchQuery(this.$route.query.search);
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    await this.searching();
  },
  unmounted() {
    this.clearSearchData();
  },
};
</script>
