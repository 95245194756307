<template>
  <DataTable
    stripedRows
    responsiveLayout="scroll"
    filterDisplay="row"
    class="p-datatable-sm"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Показано {first} з {last} із {totalRecords}"
    :rowHover="true"
    :value="departments"
  >
    <template #empty> Дані відсутні </template>
    <template #header>
      <div
        class="flex flex-wrap align-items-center justify-content-between gap-2"
      >
        <span class="text-xl text-900 font-bold">{{ title }}</span>
        <Button
          rounded
          raised
          icon="pi pi-plus"
          v-tooltip.left="`Додати новий розділ`"
          @click="createDepartment()"
        />
      </div>
    </template>
    <Column field="id" bodyStyle="text-align: center;">
      <template #header>
        <div class="w-full text-center">ID</div>
      </template>
    </Column>
    <Column header="Назва" field="title" headerStyle="width: 20%"> </Column>
    <Column field="url" bodyStyle="text-align: center;">
      <template #header>
        <div class="w-full text-center">URL</div>
      </template>
    </Column>
    <Column
      field="is_admin"
      bodyStyle="text-align: center;"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #header>
        <div class="w-full text-center">Розділ</div>
      </template>
      <template #filter>
        <ColumnFilterPanel
          :placeholder="'Усі'"
          :filterItems="departmentTypes"
          :labelOption="'name'"
          :key="filterKey"
          :editable="false"
          @applyFilters="
            (val) => {
              getItemsByFilters(filterByTypeID, val);
            }
          "
          @clearFilters="
            () => {
              getItemsByFilters(filterByTypeID, null);
            }
          "
        />
      </template>
      <template #body="slotProps">
        <span>
          {{ getTypeName(slotProps.data.type) }}
        </span>
      </template>
    </Column>
    <Column field="position" bodyStyle="text-align: center;">
      <template #header>
        <div class="w-full text-center">Позиція</div>
      </template>
    </Column>
    <Column
      headerStyle="width: 15%"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #filter>
        <ColumnFilterPanel
          :showOptions="false"
          @clearFilters="
            () => {
              filterKey++;

              $emit('clearFilters');
            }
          "
        />
      </template>
      <template #body="slotProps">
        <TableItemControls
          :showViewBtn="false"
          :showRemoveBtn="false"
          :itemName="`${slotProps.data.surname} ${slotProps.data.firstname} ${slotProps.data.lastname}`"
          @editFunc="editDepartment(slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>

  <DynamicDialog />
</template>

<script>
import TableItemControls from "../ui/TableItemControls.vue";
import UserForm from "./DepartmentForm.vue";
import DynamicDialog from "primevue/dynamicdialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import ColumnFilterPanel from "../ui/ColumnFilterPanel.vue";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import { mapActions } from "vuex";
import { showDialog } from "@/helpers/dialog";

export default {
  components: {
    TableItemControls,
    DynamicDialog,
    DataTable,
    Column,
    Button,
    ColumnFilterPanel,
  },
  props: {
    departments: Array,
    title: String,
    filters: Object,
  },
  emits: ["getItemsByFilter", "clearFilters"],
  data() {
    return {
      filterKey: 0,
      filterByTypeID: {
        field: filters.departaments.filterByTypeID,
        value: null,
      },
      departmentTypes: [],
    };
  },
  methods: {
    ...mapActions(["removeUserData"]),

    editDepartment(department) {
      showDialog(UserForm, {
        props: {
          header: `Редагувати розділ`,
          style: {
            width: "90vw",
          },
          modal: true,
        },
        data: {
          department: department,
          filters: this.filters,
        },
      });
    },
    createDepartment() {
      showDialog(UserForm, {
        props: {
          header: `Створити розділ`,
          style: {
            width: "90vw",
          },
          modal: true,
        },
        data: {
          filters: this.filters,
        },
      });
    },
    getItemsByFilters(filter, value) {
      filter.value = value;

      this.$emit("getItemsByFilter", filter);
    },
    getTypeName(id) {
      return this.departmentTypes.find((el) => el.id === id)?.name;
    },
  },
  mounted() {
    this.departmentTypes = Object.keys(types.department.type).map(
      (el) => types.department.type[el]
    );
  },
};
</script>
