import AuthView from "../views/administration/AuthView.vue";
import WorkspaceView from "../views/administration/WorkspaceView.vue";
import AdminHomePageView from "../views/administration/admin/AdminHomePageView.vue";
import EditPostView from "../views/administration/posts/EditPostView.vue";
import PostsView from "../views/administration/posts/PostsView.vue";
import UsersView from "../views/administration/admin/UsersView.vue";
import DepartmentsView from "../views/administration/admin/DepartmentsView.vue";

const ADMIN_PAGES = [
  {
    path: "admin",
    name: "admin-auth",
    meta: { isAdmin: true },
    component: AuthView,
  },
  {
    path: "workspace",
    component: WorkspaceView,
    meta: { requiresAdminAuth: true, isAdmin: true },
    children: [
      {
        path: "",
        name: "admin-workspace",
        component: AdminHomePageView,
      },
      {
        path: "posts",
        children: [
          {
            path: "",
            name: "admin-posts",
            component: PostsView,
          },
          {
            path: "create",
            name: "admin-create-post",
            component: EditPostView,
          },
          {
            path: "edit/:id",
            name: "admin-edit-post",
            component: EditPostView,
          },
        ],
      },
      {
        path: "managers",
        name: "admin-managers",
        component: UsersView,
      },
      {
        path: "departments",
        name: "admin-departments",
        component: DepartmentsView,
      },
    ],
  },
];

export default ADMIN_PAGES;
