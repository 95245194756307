import instance from "../instance";

import posts from "./posts";
import departments from "./departments";
import auth from "./auth";
import user from "./user";
import registeration from "./registration";
import regions from "./regions";
import search from "./search";

const VUE_APP_API_PUBLIC_URL = process.env.VUE_APP_API_PUBLIC_URL;

export default {
  posts: posts(instance),
  departments: departments(instance),
  auth: auth(instance),
  user: user(VUE_APP_API_PUBLIC_URL, instance),
  registeration: registeration(instance),
  regions: regions(instance),
  search: search(instance),
};
