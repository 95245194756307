<template>
  <BreadcrumbsList :breadcrumbs="breadcrumbs" />

  <div class="grid">
    <div class="col-12 lg:col-8">
      <div class="content mt-3 post-title-wrapper">
        <div>
          <h4 class="mt-0">Заголовок публікації</h4>
          <InputText v-model="post.title" type="text" class="w-full" required />
        </div>
        <div v-show="post.id_post_type !== COMPENSATION_POST_TYPE">
          <h4 class="mt-3 mb-1">Посилання</h4>
          <div class="flex align-items-center gap-2">
            <span
              v-show="
                post.id_post_type === PAGE_POST_TYPE ||
                post.id_post_type === NEWS_POST_TYPE
              "
            >
              {{ itemPath }}
            </span>
            <InputText
              v-model="tempURL"
              type="text"
              class="line w-full"
              :disabled="generateURL"
            />
            <Button
              v-show="
                post.id_post_type === PAGE_POST_TYPE ||
                post.id_post_type === NEWS_POST_TYPE
              "
              raised
              icon="pi pi-copy"
              severity="secondary"
              class="url-copy-btn"
              v-tooltip.bottom="'Копіювати посилання'"
              @click="copyURL()"
            />
          </div>
        </div>
      </div>
      <div
        v-show="post.id_post_type !== COMPENSATION_POST_TYPE"
        class="content mt-3 post-short-text-wrapper"
      >
        <h4 class="mt-0">Короткий опис публікації</h4>
        <VueTrumbowyg
          v-model="post.short_text"
          class="form-control post_description_editor"
          name="short_text"
          :config="shortEditorConfig"
        />
      </div>
      <div
        v-if="post.id_post_type !== SLIDER_POST_TYPE"
        class="content mt-3 post-text-wrapper"
      >
        <h4 class="mt-0">Текст публікації</h4>
        <VueTrumbowyg
          v-model="post.text"
          class="form-control post_text_editor"
          name="text"
          :config="fullEditorConfig"
        />
      </div>
    </div>
    <div class="col-12 lg:col-4">
      <div class="content mt-3">
        <div class="grid gap-3 flex justify-content-center">
          <ToggleButton
            v-model="isPublished"
            onLabel="Опублікувати"
            offLabel="Як чернетка"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            v-tooltip.bottom="'Статус публікації'"
          />
          <Button
            label="Зберегти"
            severity="secondary"
            text
            raised
            :disabled="
              post.title === null ||
              (isAdmin && post.id_departament === null) ||
              post.id_post_type === null
            "
            @click="savePost(post)"
          />
        </div>
      </div>
      <div class="content mt-3">
        <div v-if="isAdmin" class="mb-3">
          <h4 class="mt-0 mb-1">Розділ</h4>
          <Dropdown
            editable
            v-model="post.id_departament"
            optionValue="id"
            optionLabel="title"
            placeholder="Розділ для публікації"
            class="w-full"
            :options="pagesList"
            @change="
              () => {
                getDepartmentPath();
                createURLPath(this.post.title);
              }
            "
          />
        </div>
        <div class="mb-3">
          <h4 class="mt-0 mb-1">Тип публікації</h4>
          <Dropdown
            editable
            v-model="post.id_post_type"
            optionValue="id"
            optionLabel="name"
            placeholder="Тип публікації"
            class="w-full"
            :options="postTypes.rows"
            @change="
              () => {
                getDepartmentPath();
                createURLPath(this.post.title);
              }
            "
          />
        </div>
        <div
          v-if="
            post.id_post_type &&
            (post.id_post_type === SIDEBAR_POST_TYPE ||
              post.id_post_type === POST_POST_TYPE)
          "
          class="mb-3"
        >
          <h4 class="mt-0 mb-1">Позиція</h4>
          <InputText
            v-model.number="post.position"
            type="number"
            class="w-full"
          />
        </div>
        <div
          v-show="
            post.id_post_type &&
            (post.id_post_type === SLIDER_POST_TYPE ||
              post.id_post_type === NEWS_POST_TYPE)
          "
          class="mb-3"
        >
          <h4 class="mt-0 mb-1">Тег</h4>
          <InputText v-model="post.tag" type="text" class="w-full" />
        </div>
        <div v-show="post.id_post_type === COMPENSATION_POST_TYPE">
          <h4 class="mt-0 mb-1">Територіальна громада</h4>
          <PostRegion v-model:id_region_3="post.id_region_3" />
        </div>
      </div>
      <div
        v-show="post.id_post_type === SIDEBAR_POST_TYPE"
        class="content mt-3"
      >
        <h4 class="mt-0 mb-1">Стиль сайдбару</h4>
        <div class="flex justify-content-between gap-2">
          <Dropdown
            v-model="postOptions.className"
            optionValue="value"
            optionLabel="title"
            placeholder="Стиль сайдбару"
            :class="['mb-4', isAdmin ? 'w-8' : 'w-full']"
            :options="sidebarStyles"
          />
          <div v-if="isAdmin" class="w-5">
            <Button
              label="Видалити стиль"
              class="w-full"
              :disabled="postOptions.className === DEFAULT_SIDEBAR_STYLE"
              @click="postOptions.className = DEFAULT_SIDEBAR_STYLE"
            />
          </div>
        </div>
        <div class="sidebar">
          <div class="sidebar-item-wrapper">
            <div
              :class="[
                'block sidebar-item',
                testSideBarItem.text ? '' : 'flex align-items-center',
                postOptions.className,
              ]"
            >
              <SideBarItem :item="testSideBarItem" />
            </div>
            <div
              class="sidebar-item-description"
              v-html="testSideBarItem.short_text"
            ></div>
          </div>
        </div>
      </div>
      <div
        v-show="post.id_post_type === SIDEBAR_POST_TYPE"
        class="content mt-3"
      >
        <UploadImgPanel
          :title="'Логотип'"
          :id="'logo_img'"
          :img="logoImg"
          :imgSrc="post.logo"
          @update:img="logoImg = $event"
          @update:imgSrc="post.logo = $event"
        />
      </div>

      <div
        v-show="
          post.id_post_type === NEWS_POST_TYPE ||
          post.id_post_type === SLIDER_POST_TYPE ||
          post.id_post_type === PAGE_POST_TYPE
        "
        class="content mt-3"
      >
        <UploadImgPanel
          :title="'Головне зображення'"
          :id="'header_img'"
          :img="headerImg"
          :imgSrc="post.img"
          @update:img="headerImg = $event"
          @update:imgSrc="post.img = $event"
        />
      </div>
    </div>
  </div>
  <div ref="reference"></div>
</template>

<script>
import BreadcrumbsList from "@/components/admin/ui/BreadcrumbsList.vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ToggleButton from "primevue/togglebutton";
import VueTrumbowyg from "vue-trumbowyg";
import Dropdown from "primevue/dropdown";
import UploadImgPanel from "@/components/admin/ui/UploadImgPanel.vue";
import SideBarItem from "@/components/public/content/SideBarItem.vue";
import PostRegion from "@/components/admin/posts/editor/PostRegion.vue";
import "vue3-colorpicker/style.css";

import editor_config from "@/config/editor.yml";
import transliteration_config from "@/config/transliteration.yml";
import types_config from "@/config/types.yml";

import { mapActions, mapGetters, mapState } from "vuex";
import { createFilters } from "@/helpers/filters";
import { showUserNotify } from "@/helpers/notify";

// trumbowyg styles
import "trumbowyg/dist/ui/trumbowyg.css";
import "trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css";
import "trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css";

// trumbowyg plugins
import "trumbowyg/dist/plugins/base64/trumbowyg.base64.min.js";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js";
import "trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js";
import "trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js";
import "trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min.js";
import "trumbowyg/dist/plugins/indent/trumbowyg.indent.min.js";
import "trumbowyg/dist/plugins/table/trumbowyg.table.min.js";
import "trumbowyg/dist/plugins/upload/trumbowyg.upload.min.js";
import "trumbowyg/dist/plugins/resizimg/trumbowyg.resizimg.min.js";
import "trumbowyg/dist/langs/ua.min.js";
import "jquery-resizable-dom/dist/jquery-resizable";

const DEFAULT_SIDEBAR_STYLE = "sidebar-item-default";

export default {
  name: "PostEditor",
  components: {
    BreadcrumbsList,
    InputText,
    Button,
    ToggleButton,
    VueTrumbowyg,
    Dropdown,
    UploadImgPanel,
    SideBarItem,
    PostRegion,
  },
  data() {
    return {
      breadcrumbs: [],
      siteURL: process.env.VUE_APP_API_URL,

      // editor config
      shortEditorConfig: editor_config.short_editor_config,
      fullEditorConfig: this.createFullEditorConfig(),

      // post data
      post: {
        id_post_type: null,
        id_departament: null,
        id_region_3: 0,
        position: 1,
        url: null,
        title: null,
        short_text: null,
        text: null,
        img: null,
        logo: null,
        tag: null,
        bg_img: null,
        bg_color: null,
        text_color: null,
        options: null,
        is_published: null,
      },

      headerImg: null,
      bgImg: null,
      logoImg: null,

      isPublished: false,
      generateURL: false,
      filterByPostID: {
        field: "posts.id",
        value: "",
      },
      filterPostsByDepartmentsID: {
        field: "posts.id_departament",
        value: "",
      },
      filters: {},
      itemPath: "",
      tempURL: "",
      sidebarStyles: [
        { id: 1, title: "Стандартний", value: DEFAULT_SIDEBAR_STYLE },
        { id: 2, title: "Головний", value: "sidebar-item-light" },
        { id: 3, title: "Інформаційний", value: "sidebar-item-primary" },
        { id: 4, title: "Книга пам`яті", value: "sidebar-item-dark" },
        { id: 5, title: "Telegram", value: "sidebar-item-telegram" },
      ],
      postOptions: {
        className: DEFAULT_SIDEBAR_STYLE,
      },
      testSideBarItem: {
        title: "Тестовий варіант",
        text: "<p>Тестовий варіант</p>",
        short_text: "<p>Тестовий варіант</p>",
      },

      //post types
      POST_POST_TYPE: types_config.post.post,
      SLIDER_POST_TYPE: types_config.post.slider,
      PAGE_POST_TYPE: types_config.post.page,
      NEWS_POST_TYPE: types_config.post.news,
      SIDEBAR_POST_TYPE: types_config.post.sidebar,
      COMPENSATION_POST_TYPE: types_config.post.compensation,
      DEFAULT_SIDEBAR_STYLE,
    };
  },
  computed: {
    ...mapState({
      postsData: (state) => state.posts.postsData,
      postTypes: (state) => state.posts.postTypes,
      departmentsData: (state) => state.departments.departmentsData,
    }),
    ...mapGetters([
      "managerDepartmentID",
      "isAdmin",
      "departmentByField",
      "pagesList",
      "adminToken",
    ]),

    postData() {
      return this.postsData.rows[0];
    },
  },
  watch: {
    post() {
      this.colorsPanelKey++;
    },
    "post.title"(newTitle) {
      if (
        this.post.id_post_type === this.PAGE_POST_TYPE ||
        this.post.id_post_type === this.NEWS_POST_TYPE
      )
        this.createURLPath(newTitle);
    },
    "post.id_post_type"(postType) {
      if (this.post.id_region_3 && postType !== this.COMPENSATION_POST_TYPE) {
        this.post.id_region_3 = 0;
      }
    },
  },
  methods: {
    ...mapActions([
      "getPostsData",
      "createPostData",
      "updatePostData",
      "getPostTypesData",
      "clearPostsData",
      "getDepartmentsData",
    ]),

    createFilters,

    getDepartmentPath() {
      let departmentURL = "";
      if (this.post.id_departament && this.post.id_departament !== 0) {
        departmentURL =
          this.departmentByField({
            field: "id",
            value: this.post.id_departament,
          })?.url + "/";
      }

      switch (this.post.id_post_type) {
        case this.NEWS_POST_TYPE:
          this.itemPath = `${departmentURL}news/`;
          break;
        case this.PAGE_POST_TYPE:
          this.itemPath = departmentURL ? `${departmentURL}` : "page/";

          break;
        default:
          break;
      }
    },
    async savePost(post) {
      if (!localStorage.getItem("post_filters")) {
        if (!this.isAdmin) {
          this.filterPostsByDepartmentsID.value = this.managerDepartmentID;
          this.filters = createFilters(this.filters, [
            this.filterPostsByDepartmentsID,
          ]);
        }
      } else {
        this.filters = JSON.parse(localStorage.getItem("post_filters"));
      }

      post.is_published = this.isPublished ? 1 : 0;
      post.url = this.tempURL;
      post.options = JSON.stringify(this.postOptions);

      let postData = new FormData();

      postData.append("data", JSON.stringify(post));
      postData.append("img", this.headerImg);
      postData.append("logo", this.logoImg);
      postData.append("bg_img", this.bgImg);

      if (this.$route.params.id) {
        await this.updatePostData({
          id: this.$route.params.id,
          post_data: postData,
          filters: this.filters,
        });
      } else {
        await this.createPostData({
          post_data: postData,
          filters: this.filters,
        });
      }

      this.$router.go(-1);
    },
    parsePostOptions(options) {
      let postOptionsData = "";
      try {
        postOptionsData = JSON.parse(options);
      } catch (error) {
        postOptionsData = "";
      }

      postOptionsData.className
        ? (this.postOptions.className = postOptionsData.className)
        : "";
    },
    createURLPath(title) {
      if (
        title &&
        (this.post.id_post_type === this.NEWS_POST_TYPE ||
          this.post.id_post_type === this.PAGE_POST_TYPE)
      ) {
        this.tempURL = title
          .toLowerCase()
          .split("")
          .map((char) => {
            if (
              transliteration_config.char_map[char] ||
              transliteration_config.char_map[char] === ""
            ) {
              return transliteration_config.char_map[char];
            } else {
              return char;
            }
          })
          .join("")
          .trim()
          .replace(/[^a-z0-9]/gm, "-");
      } else {
        this.tempURL = "";
      }
    },
    copyURL() {
      try {
        let fullURL = this.itemPath + this.tempURL;

        const storage = document.createElement("textarea");
        storage.value = fullURL;

        this.$refs.reference.appendChild(storage);

        storage.select();
        storage.setSelectionRange(0, 99999);
        document.execCommand("copy");

        this.$refs.reference.removeChild(storage);

        showUserNotify("success", "Посилання скопійовано");
      } catch (e) {
        showUserNotify("warn", "Помилка копіювання посилання");
      }
    },
    createFullEditorConfig() {
      let config = editor_config.full_editor_config;
      config.plugins.upload = {
        serverPath: `${process.env.VUE_APP_API_URL}api/administration/pasteimg`,
        fileFieldName: "embedded_img",
        headers: {
          Authorization: `Bearer ${this.adminToken}`,
        },
        urlPropertyName: "img",
        statusPropertyName: "img",
      };

      return config;
    },
  },
  async mounted() {
    try {
      if (this.isAdmin && this.departmentsData.rows.length == 0) {
        await this.getDepartmentsData({
          pageSize: -1,
          filtered: [],
        });
      }
      await this.getPostTypesData();

      if (this.$route.params.id) {
        this.filterByPostID.value = this.$route.params.id;
        this.filters = createFilters(this.filters, [this.filterByPostID]);

        await this.getPostsData(this.filters);

        this.post = { ...this.postData };
        delete this.post.id;

        this.isPublished = !!this.postData.is_published;

        this.parsePostOptions(this.post.options);
      }

      this.getDepartmentPath();
    } catch (e) {
      console.log();
    }

    this.tempURL = this.post.url;
    this.breadcrumbs = [
      {
        label: "Публікації",
        to: { name: this.isAdmin ? "admin-posts" : "posts" },
      },
      { label: this.$route.params.id ? this.post.title : "Створити" },
    ];
  },
};
</script>
