<template>
  <DataTable
    stripedRows
    responsiveLayout="scroll"
    filterDisplay="row"
    class="p-datatable-sm"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Показано {first} з {last} із {totalRecords}"
    :rowHover="true"
    :value="users"
  >
    <template #empty> Дані відсутні </template>
    <template #header>
      <div
        class="flex flex-wrap align-items-center justify-content-between gap-2"
      >
        <span class="text-xl text-900 font-bold">{{ title }}</span>
        <Button
          rounded
          raised
          icon="pi pi-plus"
          v-tooltip.left="
            `Додати нового
        ${this.$route.name === 'users' ? 'користувача' : 'менеджера'}`
          "
          @click="createUser()"
        />
      </div>
    </template>
    <Column header="П.І.Б" headerStyle="width: 20%">
      <template #body="slotProps">
        <div>
          <span>
            {{
              `${slotProps.data.surname} ${slotProps.data.firstname} ${slotProps.data.lastname}`
            }}
          </span>
        </div>
      </template>
    </Column>
    <Column
      headerStyle="width: 20%"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #header>
        <div class="text-center w-full">
          <span>Група</span>
        </div>
      </template>
      <template #filter>
        <ColumnFilterPanel
          v-if="$route.name !== 'users'"
          :placeholder="'Усі'"
          :filterItems="departments"
          :labelOption="'title'"
          :key="filterKey"
          @applyFilters="
            (val) => {
              getItemsByFilters(filterPostsByDepartmentsID, val);
            }
          "
          @clearFilters="
            () => {
              getItemsByFilters(filterPostsByDepartmentsID, null);
            }
          "
        />
      </template>
      <template #body="slotProps">
        <div class="text-center">
          <span>{{ getUserDepartment(slotProps.data.id_departament) }}</span>
        </div>
      </template>
    </Column>
    <Column field="is_active" headerStyle="width: 10%">
      <template #header>
        <div class="text-center w-full">
          <span>Статус</span>
        </div>
      </template>
      <template #body="slotProps">
        <div class="text-center">
          <Tag
            :severity="!!slotProps.data.is_active ? 'success' : 'warning'"
            :value="!!slotProps.data.is_active ? 'Активний' : 'Відключений'"
          />
        </div>
      </template>
    </Column>
    <Column
      headerStyle="width: 15%"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #filter>
        <ColumnFilterPanel
          :showOptions="false"
          @clearFilters="
            () => {
              filterKey++;

              $emit('clearFilters');
            }
          "
        />
      </template>
      <template #body="slotProps">
        <TableItemControls
          :showViewBtn="false"
          :itemName="`${slotProps.data.surname} ${slotProps.data.firstname} ${slotProps.data.lastname}`"
          @editFunc="editUser(slotProps.data)"
          @removeFunc="removeUser(slotProps.data.id)"
        />
      </template>
    </Column>
  </DataTable>

  <DynamicDialog />
</template>

<script>
import TableItemControls from "../ui/TableItemControls.vue";
import ColumnFilterPanel from "../ui/ColumnFilterPanel.vue";
import UserForm from "./UserForm.vue";
import DynamicDialog from "primevue/dynamicdialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Tag from "primevue/tag";

import filters from "@/config/filters.yml";

import { mapActions, mapGetters, mapState } from "vuex";
import { showDialog } from "@/helpers/dialog";

export default {
  components: {
    TableItemControls,
    ColumnFilterPanel,
    DynamicDialog,
    DataTable,
    Column,
    Button,
    Tag,
  },
  props: {
    users: Array,
    title: String,
  },
  emits: ["getItemsByFilter", "clearFilters"],
  data() {
    return {
      filterKey: 0,
      filterPostsByDepartmentsID: {
        field: filters.users.filterByDepartmentID,
        value: null,
      },
    };
  },
  computed: {
    ...mapState({
      departmentsData: (state) => state.departments.departmentsData,
    }),
    ...mapGetters(["departmentByField"]),

    departments() {
      let departmentsList = [{ id: 1, title: "Адміністрація" }];

      return departmentsList.concat(
        this.departmentsData.rows.filter((item) => item.is_admin === 1)
      );
    },
  },
  methods: {
    ...mapActions(["removeUserData"]),

    getUserDepartment(id) {
      return id === 1
        ? "Адміністрація"
        : this.departmentByField({
            field: "id",
            value: id,
          })?.title;
    },
    createUser() {
      showDialog(UserForm, {
        props: {
          header: `Новий ${
            this.$route.name === "users" ? "користувач" : "менеджер"
          }`,
          modal: true,
        },
        data: {
          user: null,
        },
      });
    },
    editUser(user) {
      showDialog(UserForm, {
        props: {
          header: `Редагувати ${
            this.$route.name === "users" ? "користувача" : "менеджера"
          }`,
          modal: true,
        },
        data: {
          user: user,
        },
      });
    },
    async removeUser(id) {
      await this.removeUserData(id);
    },
    getItemsByFilters(filter, value) {
      filter.value = value;

      this.$emit("getItemsByFilter", filter);
    },
  },
};
</script>
