/* eslint-disable no-useless-catch */
import api from "@/api/public/index";

export default {
  state: {
    user: null,
  },
  mutations: {
    setUserData(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async getUserData({ commit }) {
      try {
        const user = (await api.user.getProfile()).data.data;

        commit("setUserData", user);
      } catch (e) {
        throw e;
      }
    },
    async updateUserProfile({ commit, dispatch }, payload) {
      try {
        const token = (await api.user.updateUserProfile(payload)).data.token;

        commit("savePublicToken", { token: token });
        dispatch("getUserData");
      } catch (e) {
        throw e;
      }
    },
  },
};
