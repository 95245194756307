import { showErrorNotify } from "@/helpers/notify";

export default (instance) => {
  return {
    getDepartmentsPublicData(payload) {
      return instance
        .post(`api/departaments`, payload)
        .catch((error) => showErrorNotify(error));
    },
    getDepartment(url) {
      return instance
        .get(`api/departaments/${url}`)
        .catch((error) => showErrorNotify(error));
    },
  };
};
