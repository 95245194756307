<template>
  <button
    class="scroll-top-btn"
    :style="`opacity: ${opacity}`"
    @click="scrollTop"
  ></button>
</template>

<script>
export default {
  data() {
    return {
      opacity: 0,
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    createOpacity() {
      let tempOpacity = window.scrollY / window.innerHeight;
      this.opacity = tempOpacity > 1 ? 1 : tempOpacity;
    },
  },
  mounted() {
    document.addEventListener("scroll", this.createOpacity);
  },
  unmounted() {
    document.removeEventListener("scroll", this.createOpacity);
  },
};
</script>
