<template>
  <Dialog
    modal
    header="Підтвердження реєстрації"
    class="register-confirm-dialog w-full"
    :visible="visible"
    :closable="false"
    :draggable="false"
  >
    <form class="auth-form mx-auto" @submit.prevent="">
      <div class="input-wrapper p-float-label mb-3">
        <InputMask
          type="text"
          id="code"
          slotChar=""
          v-model="code"
          :mask="codeValid.mask"
          :class="[
            'input-field auth-field w-full',
            !code || isValidCode ? '' : 'p-invalid',
          ]"
        />
        <label for="code" class="input-field-label">Код з SMS</label>
      </div>
    </form>
    <template #footer>
      <div class="text-center">
        <button
          class="auth-submit-btn w-full md:w-6"
          :disabled="!isValidCode"
          @click="confirmRegistration()"
        >
          Зареєструватися
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import InputMask from "primevue/inputmask";

import { mapActions } from "vuex";
import { validationCheck } from "@/helpers/validation";

export default {
  components: {
    Dialog,
    InputMask,
  },
  props: {
    visible: Boolean,
    codeValid: Object,
  },
  data() {
    return {
      code: null,
      isValidCode: false,
      REGISTER_PROCESS: "register",
    };
  },
  watch: {
    code(newCode) {
      this.isValidCode = validationCheck(newCode, this.codeValid.regex);
    },
  },
  methods: {
    ...mapActions(["getRegistrationToken"]),

    async confirmRegistration() {
      try {
        await this.getRegistrationToken({
          typeProcess: this.REGISTER_PROCESS,
          registerPayload: {
            code: parseInt(this.code),
          },
        });

        this.$router.push({ path: "/" });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
