<template>
  <UINavBar />
  <header v-if="slidersData" class="main-header">
    <div class="p-container">
      <div class="main-header-wrapper">
        <SliderBar :sliders="slidersData.rows" />
      </div>
    </div>
  </header>
  <main>
    <section class="services-section">
      <div class="p-container">
        <h1 class="section-header">
          Сервіси для учасників бойових дій, ветеранів та членів їх сімей
        </h1>
        <div class="grid">
          <div v-if="services" class="col-12 md:col-6 lg:col-8">
            <div class="grid">
              <div class="col-12 lg:col-6">
                <a :href="questionnaire.url">
                  <ServiceCard
                    class="questionnaire-card"
                    :service="questionnaire"
                  />
                </a>
              </div>
              <div
                v-for="service in services"
                :key="service.id"
                class="col-12 lg:col-6"
              >
                <router-link :to="{ path: `/${service.url}` }">
                  <ServiceCard :service="service" />
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="sidebarData.rows" class="col-12 md:col-6 lg:col-4">
            <UISideBar :sidebarItems="sidebarData.rows" />
            <div class="departments-sidebar">
              <ul class="departments-sidebar-list">
                <li
                  v-for="department in departments"
                  class="departments-sidebar-item"
                  :key="department.id"
                  :department="department"
                >
                  <router-link
                    class="block h-full relative"
                    :to="{ path: `/${department.url}` }"
                  >
                    {{ department.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="content-section latest-news-section">
      <div class="p-container">
        <h1 class="section-header flex justify-content-between">
          <span>Новини</span>
          <router-link
            class="all-news-link flex align-items-end"
            :to="{ name: 'all-news-page' }"
          >
            Всі новини
          </router-link>
        </h1>
        <div class="grid">
          <div v-if="newsData.rows" class="col-12 md:col-6 lg:col-8">
            <div class="grid">
              <div
                v-for="newsItem in newsData.rows"
                :key="newsItem.id"
                class="col-12 lg:col-6"
              >
                <NewsCard :news="newsItem" />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-4">
            <div
              ref="facebookIframeWrapper"
              class="flex justify-content-center facebook-iframe-wrapper h-full"
            >
              <iframe
                :src="`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmva.gov.ua&tabs=timeline&width=${facebookIframeWidth}&height=${facebookIframeHeight}&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId`"
                :width="facebookIframeWidth"
                :height="facebookIframeHeight"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <FooterSection />
</template>

<script>
import UINavBar from "@/components/public/ui/UINavBar.vue";
import UISideBar from "@/components/public/content/SideBar.vue";
import SliderBar from "@/components/public/content/SliderBar.vue";
import ServiceCard from "@/components/public/content/ServiceCard.vue";
import NewsCard from "@/components/public/content/NewsCard.vue";
import FooterSection from "@/components/public/content/FooterSection.vue";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import { mapActions, mapState } from "vuex";
import { createFilters } from "@/helpers/filters";

export default {
  name: "HomeView",
  components: {
    UINavBar,
    UISideBar,
    SliderBar,
    FooterSection,
    ServiceCard,
    NewsCard,
  },
  data() {
    return {
      facebookIframeWidth: 500,
      facebookIframeHeight: 600,
      postTypes: [types.post.sidebar, types.post.news, types.post.slider],
      sortPostByDate: {
        field: filters.posts.sortByDate,
        sort: "DESC",
      },
      sortByPosition: {
        field: filters.posts.sortByPosition,
        sort: "ASC",
      },
      filterPostByTypeID: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      filterPostByPublished: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      filterPostByDepartmentID: {
        field: filters.posts.filterByDepartmentID,
        value: 0,
      },
      questionnaire: {
        url: "https://forms.gle/FXCAqyPyX9Hm67G7A",
        title: "ОПИТУВАННЯ",
        short_text: `
          <h2><b>Оцінка потреб ветеранів війни та членів їх сімей з Черкаської області</b></h2>
          <p>Кількість запитань: <br/><b>До 10 запитань</b></p>
          <br>
          <b>Опитування анонімне</b>
        `,
        isQuestionnaire: true,
      },
    };
  },
  computed: {
    ...mapState({
      services: (state) => state.public_departments.services,
      departments: (state) => state.public_departments.departments,
      other: (state) => state.public_departments.other,
      newsData: (state) => state.public_posts.newsData,
      postsData: (state) => state.public_posts.postsData,
      sidebarData: (state) => state.public_posts.sidebarData,
      slidersData: (state) => state.public_posts.slidersData,
    }),
  },
  methods: {
    ...mapActions(["getPublicPostsData", "clearPublicPostsData"]),

    createFacebookIframeSize() {
      let wrapper = null;

      try {
        wrapper = this.$refs.facebookIframeWrapper;
      } catch {
        return;
      }

      this.facebookIframeWidth =
        wrapper.clientWidth >= 500
          ? this.facebookIframeWidth
          : wrapper.clientWidth;

      this.facebookIframeHeight =
        wrapper.clientHeight !== 0 && wrapper.clientHeight > 600
          ? wrapper.clientHeight
          : this.facebookIframeHeight;
    },
  },
  async mounted() {
    try {
      this.postTypes.forEach(async (type) => {
        let filtered = [];
        this.filterPostByTypeID.value = type;

        if (type === types.post.news || type === types.post.slider) {
          type === types.post.slider
            ? (this.filterPostByDepartmentID.value = 0)
            : (this.filterPostByDepartmentID.value = null);

          filtered = [
            this.filterPostByTypeID,
            this.sortPostByDate,
            this.filterPostByPublished,
          ];

          if (type === types.post.slider) {
            filtered.push(this.filterPostByDepartmentID);
          }
        } else {
          filtered = [
            this.filterPostByDepartmentID,
            this.filterPostByTypeID,
            this.sortByPosition,
            this.filterPostByPublished,
          ];
        }

        await this.getPublicPostsData({
          departmentID: 0,
          postType: type,
          filters: createFilters({ pageSize: -1 }, filtered),
        });
      });
    } catch (e) {
      console.log(e);
    }

    window.addEventListener("load", () => this.createFacebookIframeSize());
  },
  unmounted() {
    this.clearPublicPostsData();
  },
};
</script>
