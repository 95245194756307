<template>
  <div class="grid"></div>
</template>

<script>
export default {
  name: "AdminHomeView",
  components: {},
  data() {
    return {};
  },
};
</script>
