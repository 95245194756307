<template>
  <div class="personal-info-item text-center md:flex justify-content-center">
    <div
      v-show="!edit"
      class="personal-info-item-name w-full md:w-6 text-center md:text-right"
    >
      <span>
        {{ `${header}: ` }}
      </span>
    </div>
    <div
      v-show="!edit"
      class="personal-info-item-value w-full md:w-6 text-center md:text-left"
    >
      <span v-show="!edit"> {{ value }}</span>
    </div>
    <div
      v-show="edit && editable"
      class="personal-info-item-value w-full md:w-6 text-center md:text-left"
    >
      <div class="input-wrapper p-float-label">
        <InputText
          v-model="field"
          :type="type"
          :id="id"
          class="input-field auth-field w-full"
        />
        <label :for="id" class="input-field-label"> {{ header }} </label>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";

export default {
  components: {
    InputText,
  },
  props: {
    value: [String, Number],
    header: String,
    id: String,
    type: String,
    edit: Boolean,
    editable: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ["update:value", "update:error"],
  watch: {
    field(newVal) {
      this.$emit("update:value", newVal);
    },
  },
  data() {
    return {
      field: null,
    };
  },
  computed: {
    getValue() {
      return this.value;
    },
  },
  mounted() {
    this.field = this.getValue;
  },
};
</script>
