<template>
  <a
    :href="
      news.departaments.url
        ? news?.departaments?.url + `/news/${news?.url}`
        : `news/${news?.url}`
    "
  >
    <div class="card news-card">
      <div class="card-header news-card-header">
        <div class="card-header-img news-card-header-img relative">
          <img
            v-if="news?.img"
            class="news-card-logo"
            :src="siteURL + news?.img"
          />
          <img
            v-else
            class="news-card-logo"
            src="@/assets/img/content/news_default.png"
          />
          <div v-if="news?.departaments?.tag" class="department-tag news-tag">
            {{ news?.departaments?.tag }}
          </div>
        </div>
      </div>
      <div class="card-body news-card-body">
        <div class="news-card-info">
          <span class="news-date">{{ news?.updated_at }}</span>
        </div>
        <span class="card-body-text news-card-body-text">
          {{ news?.title }}
        </span>
      </div>
      <div class="card-footer news-card-footer"></div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    news: Object,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
};
</script>
