import app from "../main";
import network_errors from "../config/network_errors.yml";

const NOTIFY_MESSAGE_DEFAULT = {
  success: "Зміни збережено",
  error: "Помилка запиту",
};

function createNotify(type, message, delay) {
  app.config.globalProperties.$toast.add({
    severity: type,
    detail: message ? message : NOTIFY_MESSAGE_DEFAULT[type],
    life: delay ? delay : 3000,
  });
}

function showErrorNotify(error) {
  let codeTranslate = NOTIFY_MESSAGE_DEFAULT["error"];

  if (error.response) {
    if (network_errors.errors_msg[error.response.data.msg]) {
      codeTranslate = network_errors.errors_msg[error.response.data.msg];
    } else if (network_errors.errors_code[error.response.status]) {
      codeTranslate = network_errors.errors_code[error.response.status];
    }
  }

  createNotify("error", codeTranslate);
}
function showUserNotify(type, message, delay) {
  createNotify(type, message, delay ? delay : 3000);
}

export { showErrorNotify, showUserNotify };
