<template>
  <Sidebar
    v-model:visible="visible"
    :showCloseIcon="false"
    :modal="false"
    :dismissable="isMobile"
    :blockScroll="false"
    :class="isMobile ? 'w-full' : sideWidth"
  >
    <template #default>
      <div class="mt-4">
        <PanelMenu :model="sideBarMenuItems" />
      </div>
    </template>
  </Sidebar>
</template>

<script>
import Sidebar from "primevue/sidebar";
import PanelMenu from "primevue/panelmenu";

export default {
  components: {
    Sidebar,
    PanelMenu,
  },
  props: {
    sideBarMenuItems: Array,
    showSideBar: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {
      visible: true,
      sideWidth: "",
    };
  },
  watch: {
    visible(newValue) {
      this.$emit("update:showSideBar", newValue);
    },
    showSideBar() {
      this.visible = this.showSideBar;
    },
  },
  methods: {
    getSideWidth(windowWidth) {
      if (windowWidth >= 1200) {
        this.sideWidth = "w-2";
      } else if (windowWidth <= 1200 && windowWidth >= 576) {
        this.sideWidth = "w-3";
      }
    },
  },
  mounted() {
    let windowWidth = window.innerWidth;

    this.getSideWidth(windowWidth);
    window.addEventListener("resize", () => {
      windowWidth = window.innerWidth;
      this.getSideWidth(windowWidth);
    });
  },
};
</script>
