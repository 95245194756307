<template>
  <div class="grid">
    <!-- <div class="col-12">
      <div class="content">
        <PostsTable
          :title="'Останні публікації'"
          :posts="posts"
          :filters="filters"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
// import PostsTable from "@/components/posts/PostsTable.vue";
// import { mapActions, mapGetters } from "vuex";

// export default {
// name: "ManagerHomeView",
// components: {
//   PostsTable,
// },
// data() {
//   return {
//     filterPostsByDepartmentsID: {
//       field: "posts.id_departament",
//       value: "",
//     },
//     filters: {},
//     search: "",
//   };
// },
// async mounted() {
//   await this.getPosts();
// },
// unmounted() {
//   this.clearPostsData();
// },
// };
</script>
