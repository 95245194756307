function runScrollPaginator(itemClassName, func) {
  let options = {
    root: null,
    rootMargin: "200px",
    threshold: 1.0,
  };

  try {
    let observer = new IntersectionObserver((entries) => {
      let el = entries[0];

      if (el.isIntersecting) {
        func();
      }
    }, options);

    observer.observe(document.getElementsByClassName(itemClassName)[0]);
  } catch (e) {
    console.log(e);
    return;
  }
}

export { runScrollPaginator };
