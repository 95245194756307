import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import DialogService from "primevue/dialogservice";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

import VueGtag from "vue-gtag";

import createExcludedRoutes from "./helpers/routes";

import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../src/assets/scss/styles.scss";

import MANAGER_PAGES from "./router/manager";
import ADMIN_PAGES from "./router/admin";

let excludedRoutes = ["404-page"]
  .concat(createExcludedRoutes(MANAGER_PAGES))
  .concat(createExcludedRoutes(ADMIN_PAGES));

const app = createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(DialogService)
  .use(ConfirmationService)
  .use(ToastService)
  .use(
    VueGtag,
    {
      pageTrackerExcludedRoutes: excludedRoutes,
      config: { id: process.env.VUE_APP_GA_ID },
    },
    router
  )
  .directive("tooltip", Tooltip);

app.mount("#app");

export default app;
