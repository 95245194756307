<template>
  <UIVisualImpairment />
  <UIAuthPanel v-if="!publicTokenIsValid" />
  <div v-else class="w-full flex justify-content-between">
    <div class="user-info flex flex-column justify-content-center">
      <span class="user-fullname">{{ publicTokenData.name }}</span>
      <span class="user-post">
        {{
          publicTokenData.type_person === 1
            ? "військовий, ветеран"
            : "родич військового, ветерана"
        }}
      </span>
    </div>
    <div class="flex gap-2 pl-2">
      <Button
        v-if="$route.name !== 'user-profile'"
        rounded
        class="navbar-user-btn user-menu-btn"
        @click="$router.push({ name: 'user-profile' })"
      >
        <img src="@/assets/img/content/user.svg" alt="" srcset="" />
      </Button>

      <Button
        rounded
        class="lg:hidden navbar-user-btn user-logout-btn"
        icon="pi pi-sign-out"
        @click="$emit('signout')"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import UIAuthPanel from "./UIAuthPanel.vue";
import UIVisualImpairment from "./UIVisualImpairment.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    Button,
    UIAuthPanel,
    UIVisualImpairment,
  },
  emits: ["signout"],
  computed: {
    ...mapGetters(["publicTokenIsValid", "publicTokenData"]),
  },
};
</script>
