<template>
  <BreadcrumbsList :breadcrumbs="breadcrumbs" />

  <div class="content mt-3">
    <UsersTable
      :users="$route.name === 'users' ? usersData : managers"
      :title="$route.name === 'users' ? 'Користувачі' : 'Менеджери'"
      @getItemsByFilter="
        async (filter) => {
          filters = createFilters(filters, [filter]);
          await getUsersData(filters);
        }
      "
      @clearFilters="
        async () => {
          filters = clearFiltersList();
          await getUsersData(filters);
        }
      "
    />
  </div>
  <div class="content mt-3 p-2">
    <TablePaginator
      :rows="usersData.count"
      :totalRows="usersData.total_rows"
      @getItemsByPage="
        async (event) => {
          filters = changePageFilter(filters, event);
          await getUsersData(filters);
        }
      "
    />
  </div>
</template>

<script>
import UsersTable from "@/components/admin/users/UsersTable.vue";
import BreadcrumbsList from "@/components/admin/ui/BreadcrumbsList.vue";
import TablePaginator from "@/components/admin/ui/TablePaginator.vue";

import { mapActions, mapState } from "vuex";
import {
  createFilters,
  clearFiltersList,
  changePageFilter,
} from "@/helpers/filters";

export default {
  name: "UsersView",
  components: {
    UsersTable,
    BreadcrumbsList,
    TablePaginator,
  },
  data() {
    return {
      breadcrumbs: [
        { label: this.$route.name === "users" ? "Користувачі" : "Менеджери" },
      ],
      search: "",
      filters: {
        page: 1,
        pageSize: 20,
      },
    };
  },
  computed: {
    ...mapState({
      usersData: (state) => state.users.usersData,
    }),

    managers() {
      if (this.usersData.rows) {
        let managerUsers = this.usersData.rows.filter((item) => {
          let fullname = item.lastname + item.firstname + item.surname;
          return fullname.toLowerCase().indexOf(this.search) > -1;
        });

        return managerUsers;
      }

      return [];
    },
  },
  methods: {
    ...mapActions(["getUsersData", "clearUsersData", "getDepartmentsData"]),

    createFilters,
    clearFiltersList,
    changePageFilter,
  },
  async mounted() {
    await this.getUsersData(this.filters);
    await this.getDepartmentsData({
      page: 1,
      pageSize: -1,
      filtered: [],
    });
  },
  unmounted() {
    this.clearUsersData();
  },
};
</script>
