<template>
  <div class="sidebar-item-header flex align-items-center" :style="style">
    <div class="sidebar-item-header-img flex align-items-center">
      <img
        class="sidebar-item-logo"
        :src="
          item?.logo ? siteURL + item?.logo : getImgUrl('sidebar_arrow.svg')
        "
      />
    </div>
    <h3 class="sidebar-item-header-text">{{ item?.title }}</h3>
  </div>
  <div
    :class="['sidebar-item-body', item?.text ? 'mt-5' : '']"
    v-html="item?.text"
  ></div>
  <div class="sidebar-item-footer"></div>
</template>
<script>
export default {
  props: {
    item: Object,
    style: String,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    getImgUrl(img) {
      return require("../../../assets/img/content/" + img);
    },
  },
};
</script>
