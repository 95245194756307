<template>
  <UIScrollTopBtn />
  <footer class="footer">
    <div class="p-container">
      <div
        class="grid align-items-center justify-content-center md:justify-content-between"
      >
        <div class="footer-logo col-12 lg:col-6">
          <router-link
            class="flex align-items-center justify-content-center lg:justify-content-start block w-full"
            :to="{ name: 'home-page' }"
          >
            <img
              src="@/assets/img/content/footer-logo.svg"
              class="logo-img footer-logo-img"
            />
            <div class="logo-text footer-logo-text">
              <span class="block logo-text-second footer-logo-text-second">
                Платформа ветерана
              </span>
              <span class="block logo-text-main footer-logo-text-main">
                Черкаський вимір
              </span>
            </div>
          </router-link>
        </div>
        <div class="footer-info text-center col-12 lg:col-6 lg:text-right">
          <span class="block">© Платформа ветерана</span>
          <span class="block">м. Черкаси, {{ getYear() }}</span>
        </div>
      </div>
      <hr />
      <div class="grid">
        <div
          v-for="link in links"
          class="col-12 md:col-4 lg:col-3"
          :key="link.id"
          :link="link"
        >
          <a class="footer-link-item block h-full w-full" :href="link.url">
            <span>
              {{ link.title }}
            </span>
          </a>
        </div>
      </div>
      <hr />

      <div
        class="footer-info flex flex-column lg:flex-row align-items-center lg:justify-content-center lg:align-items-end"
      >
        <span class="block lg:inline text-center">
          Ресурс був розроблений за підтримки компанії
        </span>
        <a href="https://mclaut.com/">
          <img
            class="mt-2 md:ml-5 md:mt-0"
            src="@/assets/img/content/mclaut_logo.png"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import UIScrollTopBtn from "@/components/public/ui/UIScrollTopBtn.vue";

export default {
  components: {
    UIScrollTopBtn,
  },
  data() {
    return {
      links: [
        {
          id: 1,
          title:
            "Комітет Верховної Ради України з питань соціальної політики та захисту прав ветеранів",
          url: "https://komspip.rada.gov.ua/",
        },
        {
          id: 2,
          title: "Офіс Президента України",
          url: "https://www.president.gov.ua/",
        },
        {
          id: 3,
          title: "Портал електронних послуг Пенсійного Фонду",
          url: "https://portal.pfu.gov.ua/",
        },
        {
          id: 4,
          title: "Міністерство у справах ветеранів України",
          url: "https://mva.gov.ua/ua",
        },
        {
          id: 5,
          title: "Кабінет Міністрів України",
          url: "https://www.kmu.gov.ua/",
        },
        { id: 6, title: "Платформа е-Ветеран", url: "http://eveteran.gov.ua/" },
        {
          id: 7,
          title: "Офіційний портал Верховної Ради України",
          url: "https://www.rada.gov.ua/",
        },
        {
          id: 8,
          title: "Конституційний Суд України",
          url: "https://ccu.gov.ua/index.php",
        },
      ],
    };
  },
  methods: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
