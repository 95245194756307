<template>
  <nav class="auth-top-nav">
    <div class="p-container">
      <div class="auth-nav-content">
        <router-link
          class="block w-full auth-nav-back-link"
          :to="{ name: 'home-page' }"
        >
          <img
            class="pr-5"
            title="На головну"
            src="@/assets/img/content/arrow_back_dark.svg"
          />
        </router-link>
      </div>
    </div>
  </nav>
  <header class="auth-header sticky">
    <div class="p-container">
      <div class="auth-header-wrapper mx-auto">
        <router-link :to="{ name: 'home-page' }">
          <div class="flex align-items-center justify-content-center">
            <div class="flex align-items-center">
              <img
                src="@/assets/img/content/ck_logo.svg"
                class="logo-img auth-logo-img"
              />
            </div>
            <div
              class="logo-text auth-logo-text flex flex-column justify-content-center"
            >
              <span class="block logo-text-second auth-logo-text-second"
                >Платформа ветерана</span
              >
              <span class="block logo-text-main auth-logo-text-main"
                >Черкаський вимір</span
              >
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </header>
  <main>
    <section class="auth-form-section">
      <div class="p-container">
        <RegisterForm
          v-if="$route.name === 'public-register-page'"
          :phoneValid="phoneValid"
          :codeValid="codeValid"
        />

        <LoginForm v-else :phoneValid="phoneValid" :codeValid="codeValid" />
      </div>
    </section>
  </main>
  <FooterSection />
</template>

<script>
import FooterSection from "../../components/public/content/FooterSection.vue";
import RegisterForm from "@/components/public/auth/RegisterForm.vue";
import LoginForm from "@/components/public/auth/LoginForm.vue";

export default {
  name: "PublicAuthView",
  components: {
    FooterSection,
    RegisterForm,
    LoginForm,
  },
  data() {
    return {
      phoneValid: {
        regex:
          /^(\+?38|8)?(039|050|063|066|067|068|091|092|093|094|095|096|097|098|099|031|032|033|034|035|036|037|038|041|042|043|044|046|047|048|049|051|052|053|054|055|056|057|058|059|061|062|064|065|069)[0-9]{7}$/,
        mask: "380999999999",
        lazy: true,
      },
      codeValid: {
        regex: /^[^0]\d{3}$/,
        mask: "9999",
        lazy: true,
      },
    };
  },
};
</script>
