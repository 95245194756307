<template>
  <div class="text-center">
    <h4 class="mt-0">
      {{ `${title}: ${color ? color : "встановлено за замовчуванням"}` }}
    </h4>
    <div class="flex align-items-center justify-content-evenly">
      <div class="border-500 surface-overlay border-3">
        <color-picker v-model:pureColor="newColor" pickerType="chrome" />
      </div>
      <Button
        label="Очистити"
        severity="secondary"
        text
        raised
        @click="$emit('update:color', null)"
      />
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import { ColorPicker } from "vue3-colorpicker";

export default {
  components: {
    Button,
    ColorPicker,
  },
  props: {
    title: String,
    color: String,
  },
  data() {
    return {
      changeColors: false,
      newColor: null,
    };
  },
  watch: {
    newColor(newValue) {
      this.$emit("update:color", newValue);
    },
  },
  mounted() {
    this.newColor = this.color;
  },
};
</script>
