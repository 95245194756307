<template>
  <DataTable
    stripedRows
    responsiveLayout="scroll"
    filterDisplay="row"
    class="p-datatable-sm"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Показано {first} з {last} із {totalRecords}"
    :rowHover="true"
    :value="posts"
  >
    <template #empty> Дані відсутні </template>
    <template #header>
      <div
        class="flex flex-wrap align-items-center justify-content-between gap-2"
      >
        <span class="text-xl text-900 font-bold">{{ title }}</span>
        <Button
          rounded
          raised
          icon="pi pi-plus"
          v-tooltip.left="'Додати нову публікацію'"
          @click="
            $router.push({
              name: $route.meta.isAdmin ? 'admin-create-post' : 'create-post',
            })
          "
        />
      </div>
    </template>
    <Column header="Назва" headerStyle="width: 20%">
      <template #body="slotProps">
        <div class="cursor-pointer" @click="viewPost(slotProps.data)">
          <span>{{ slotProps.data.title }}</span>
        </div>
      </template>
    </Column>
    <Column
      headerStyle="width: 20%"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #filter>
        <ColumnFilterPanel
          v-if="isAdmin"
          :placeholder="'Усі'"
          :filterItems="pagesList"
          :labelOption="'title'"
          :filtersName="'post_filters'"
          :key="filterKey"
          :editable="true"
          :filterField="filterPostsByDepartmentsID.field"
          @applyFilters="
            (val) => {
              getItemsByFilters(filterPostsByDepartmentsID, val);
            }
          "
          @clearFilters="
            () => {
              getItemsByFilters(filterPostsByDepartmentsID, null);
            }
          "
        />
      </template>
      <template #header>
        <div class="text-center w-full">
          <span>Розділ</span>
        </div>
      </template>
      <template #body="slotProps">
        <div
          class="cursor-pointer text-center"
          @click="viewPost(slotProps.data)"
        >
          <span>
            {{
              slotProps.data.id_departament === 0
                ? "Головна"
                : slotProps.data.departaments.title
            }}
          </span>
        </div>
      </template>
    </Column>
    <Column header="Дата" headerStyle="width: 15%">
      <template #body="slotProps">
        <div class="cursor-pointer" @click="viewPost(slotProps.data)">
          <span>{{ slotProps.data.updated_at }}</span>
        </div>
      </template>
    </Column>
    <Column header="Тип" :showFilterMenu="false" :showClearButton="false">
      <template #filter>
        <ColumnFilterPanel
          :placeholder="'Усі'"
          :filterItems="postTypes.rows"
          :labelOption="'name'"
          :key="filterKey"
          :filtersName="'post_filters'"
          :filterField="filterPostsByPostTypeID.field"
          @applyFilters="
            (val) => {
              getItemsByFilters(filterPostsByPostTypeID, val);
            }
          "
          @clearFilters="
            () => {
              getItemsByFilters(filterPostsByPostTypeID, null);
            }
          "
        />
      </template>
      <template #body="slotProps">
        <div class="cursor-pointer" @click="viewPost(slotProps.data)">
          <span>{{ slotProps.data.post_type.name }}</span>
        </div>
      </template>
    </Column>
    <Column field="is_active" headerStyle="width: 10%">
      <template #header>
        <div class="text-center w-full">
          <span>Статус</span>
        </div>
      </template>
      <template #body="slotProps">
        <div class="text-center">
          <Tag
            :severity="!!slotProps.data.is_published ? 'success' : 'warning'"
            :value="!!slotProps.data.is_published ? 'Опубліковано' : 'Чернетка'"
          />
        </div>
      </template>
    </Column>
    <Column
      headerStyle="width: 15%"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #filter>
        <ColumnFilterPanel
          :showOptions="false"
          @clearFilters="
            () => {
              filterKey++;

              $emit('clearFilters');
            }
          "
        />
      </template>
      <template #body="slotProps">
        <TableItemControls
          :itemName="slotProps.data.title"
          @viewFunc="viewPost(slotProps.data)"
          @editFunc="
            $router.push({
              name: $route.meta.isAdmin ? 'admin-edit-post' : 'edit-post',
              params: { id: slotProps.data.id },
            })
          "
          @removeFunc="removePost(slotProps.data.id)"
        />
      </template>
    </Column>
  </DataTable>

  <DynamicDialog />
</template>

<script>
import TableItemControls from "../ui/TableItemControls.vue";
import ColumnFilterPanel from "../ui/ColumnFilterPanel.vue";
import ViewPost from "./ViewPost.vue";
import DynamicDialog from "primevue/dynamicdialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Tag from "primevue/tag";

import filters from "@/config/filters.yml";

import { mapActions, mapGetters, mapState } from "vuex";
import { showDialog } from "@/helpers/dialog";

export default {
  components: {
    TableItemControls,
    DataTable,
    Column,
    Button,
    DynamicDialog,
    Tag,
    ColumnFilterPanel,
  },
  props: {
    posts: Array,
    title: String,
    filters: Object,
  },
  emits: ["getItemsByFilter", "clearFilters"],
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
      filterPostsByDepartmentsID: {
        field: filters.posts.filterByDepartmentID,
        value: null,
      },
      filterPostsByPostTypeID: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      filterKey: 0,
    };
  },
  computed: {
    ...mapState({
      postTypes: (state) => state.posts.postTypes,
    }),
    ...mapGetters(["pagesList", "departmentByField", "isAdmin"]),
  },
  methods: {
    ...mapActions(["removePostData", "getPostsData", "getPostTypesData"]),

    getItemsByFilters(filter, value) {
      filter.value = value;

      this.$emit("getItemsByFilter", filter);
    },
    viewPost(post) {
      showDialog(ViewPost, {
        props: {
          header: post.title,
          modal: true,
          maximizable: true,
        },
        data: {
          post: post,
        },
      });
    },
    async removePost(id) {
      await this.removePostData({
        id: id,
        filters: this.filters,
      });
    },
  },
  async mounted() {
    await this.getPostTypesData();
  },
};
</script>
