/* eslint-disable no-useless-catch */
import api from "@/api/public/index";
import types from "@/config/types.yml";

const DEFAULT_DATA = {
  count: 0,
  pages: 0,
  rows: [],
  total_rows: 0,
};

export default {
  state: {
    departmentsData: DEFAULT_DATA,
    departmentData: DEFAULT_DATA,

    department: null,
    services: null,
    departments: null,
    other: null,
  },
  mutations: {
    setPublicDepartmentsData(state, payload) {
      switch (payload.departmentType) {
        case types.department.type.other.id:
          state.other = payload.data.rows;
          break;
        case types.department.type.service.id:
          state.services = payload.data.rows;
          break;
        case types.department.type.department.id:
          state.departments = payload.data.rows;
          break;
        default:
          state.departmentsData = payload.data.rows;
          break;
      }
    },
    setPublicDepartmentData(state, payload) {
      state.department = payload.rows[0];
    },
    setDefaultPublicDepartmentsData(state) {
      state.departmentData = DEFAULT_DATA;
      state.departmentsData = DEFAULT_DATA;
    },
  },
  actions: {
    async getPublicDepartmentData({ commit }, payload) {
      try {
        const departmentData = (
          await api.departments.getDepartmentsPublicData(payload)
        ).data;

        commit("setPublicDepartmentData", departmentData);
      } catch (e) {
        throw e;
      }
    },
    async getPublicDepartmentsData({ commit }, payload) {
      try {
        const data = (
          await api.departments.getDepartmentsPublicData(payload.filters)
        ).data;

        commit("setPublicDepartmentsData", {
          departmentType: payload.departmentType,
          data: data,
        });
      } catch (e) {
        throw e;
      }
    },
    clearPublicDepartmentsData({ commit }) {
      commit("setDefaultPublicDepartmentsData");
    },
  },
};
