import app from "../main";

function clickToCloseOutside(ev, dialog) {
  if (
    ev.srcElement.classList.value
      .split(" ")
      .some((el) => el === "p-dialog-mask")
  ) {
    if (dialog) {
      dialog.close();
    } else {
      app.config.globalProperties.$confirm.close();
    }
  }
}

function showDialog(component, payload) {
  const dialog = app.config.globalProperties.$dialog.open(component, payload);

  document.addEventListener("click", (ev) => clickToCloseOutside(ev, dialog));

  return dialog;
}
function showConfirmDialog(payload) {
  app.config.globalProperties.$confirm.require(payload);

  document.addEventListener("click", (ev) => clickToCloseOutside(ev));
}

export { showDialog, showConfirmDialog };
