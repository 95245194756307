import { showErrorNotify } from "@/helpers/notify";

export default (adminURL, instance) => {
  return {
    signIn(payload) {
      return instance
        .post(adminURL + "auth/signin", payload)
        .catch((error) => showErrorNotify(error));
    },
    signOut() {
      return instance
        .get(adminURL + "auth/signout")
        .catch((error) => showErrorNotify(error));
    },
  };
};
