<template>
  <UINavBar />

  <header class="personal-panel-header">
    <div v-if="user" class="p-container">
      <div class="personal-panel-header-wrapper">
        <h1 class="panel-header-text m-0">Особистий кабінет</h1>
        <p class="panel-header-description">
          <span class="user-fullname">
            {{ `${user.name} ${user.surname}` }} /
          </span>
          <span class="user-type">
            {{
              user.type_person === 1
                ? "військовий, ветеран"
                : "родич військового, ветерана"
            }}
          </span>
        </p>
      </div>
      <hr class="personal-panel-divider" />
    </div>
  </header>
  <main v-if="user">
    <section class="personal-info-section">
      <div class="p-container">
        <div class="personal-info flex flex-column relative">
          <form class="flex-order-1 lg:flex-order-2 text-center">
            <h1 class="personal-info-header">дані для входу</h1>
            <ProfileInfoItem
              :id="'phone'"
              :header="'Телефон'"
              :value="user.phone"
              :type="'phone'"
              :edit="showEditPhone"
              @update:value="userData.phone = $event"
            />
          </form>
        </div>
        <hr class="personal-panel-divider" />
        <div class="personal-info flex flex-column relative">
          <form
            class="flex-order-1 lg:flex-order-2 text-center"
            @submit.prevent=""
          >
            <h1 class="personal-info-header">ОСОБИСТІ ДАНІ</h1>
            <ProfileInfoItem
              :id="'surname'"
              :header="`Прізвище`"
              :value="user.surname"
              :type="'text'"
              :edit="showEditFullName"
              @update:value="userData.surname = $event"
            />
            <ProfileInfoItem
              :id="'name'"
              :header="`Ім'я`"
              :value="user.name"
              :type="'text'"
              :edit="showEditFullName"
              @update:value="userData.name = $event"
            />
            <ProfileInfoItem
              :id="'lastname'"
              :header="`По батькові`"
              :value="user.lastname"
              :type="'text'"
              :edit="showEditFullName"
              @update:value="userData.lastname = $event"
            />
            <ProfileInfoItem
              :id="'nic'"
              :header="'Позивний'"
              :value="user.nic_name"
              :type="'text'"
              :edit="showEditFullName"
              @update:value="userData.nic_name = $event"
            />
            <ProfileInfoItem
              :id="'email'"
              :header="'E-mail'"
              :value="user.email"
              :type="'email'"
              :edit="showEditFullName"
              @update:value="userData.email = $event"
            />
            <UIEditProfileBtn
              v-model:show="showEditFullName"
              @update="updateProfile()"
            />
          </form>
        </div>
        <hr class="personal-panel-divider" />
        <div class="personal-info flex flex-column relative">
          <form
            class="flex-order-1 lg:flex-order-2 text-center"
            @submit.prevent=""
          >
            <h1 class="personal-info-header">МІСЦЕ Проживання</h1>
            <ProfileInfoItem
              :id="'area'"
              :header="'Область'"
              :value="getArea"
              :type="'text'"
            />

            <ProfileInfoItem
              :id="'region'"
              :header="'Населений пункт'"
              :value="user.region"
              :type="'text'"
              :edit="showEditAddress"
              :editable="false"
            />
            <div v-show="showEditAddress" class="personal-info-item">
              <div class="personal-info-item-value flex justify-content-center">
                <div class="input-wrapper p-float-label mb-3 w-full md:w-6">
                  <Dropdown
                    editable
                    id="region"
                    class="input-dropdown w-full"
                    optionLabel="full_name"
                    optionValue="id"
                    v-model="userData.id_region"
                    :disabled="!showEditAddress"
                    :class="[
                      'auth-field w-full',
                      userData.id_region ? '' : 'p-invalid',
                    ]"
                    :options="regions"
                  />
                  <label for="region" class="input-field-label">
                    Населений пункт
                  </label>
                </div>
              </div>
            </div>
            <ProfileInfoItem
              :id="'address'"
              :header="'Адреса'"
              :value="user.address"
              :type="'text'"
              :edit="showEditAddress"
              @update:value="userData.address = $event"
            />
            <UIEditProfileBtn
              v-model:show="showEditAddress"
              @update="updateProfile()"
            />
          </form>
        </div>
        <hr class="personal-panel-divider" />
      </div>
    </section>
  </main>

  <FooterSection />
</template>

<script>
import FooterSection from "@/components/public/content/FooterSection.vue";
import ProfileInfoItem from "@/components/public/user_panel/ProfileInfoItem.vue";
import UINavBar from "@/components/public/ui/UINavBar.vue";
import UIEditProfileBtn from "../../../components/public/user_panel/UIEditProfileBtn.vue";
import Dropdown from "primevue/dropdown";

import { mapActions, mapState } from "vuex";

export default {
  name: "AllNewsView",
  components: {
    UINavBar,
    FooterSection,
    ProfileInfoItem,
    UIEditProfileBtn,
    Dropdown,
  },
  data() {
    return {
      cherkassyArea: 1,
      userData: {
        phone: null,
        email: null,
        surname: null,
        name: null,
        lastname: null,
        nic_name: null,
        type_person: 1,
        id_region: null,
        address: null,
      },
      showEditAddress: false,
      showEditFullName: false,
      showEditPhone: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.public_user.user,
      regions: (state) => state.public_regions.regions,
      cherkassyRegions: (state) => state.public_regions.cherkassyRegions,
    }),

    getArea() {
      if (this.cherkassyRegions) {
        return this.cherkassyRegions[0].name;
      }
      return null;
    },
  },
  methods: {
    ...mapActions([
      "getUserData",
      "getCherkassyRegionsData",
      "getRegionsData",
      "updateUserProfile",
    ]),

    async updateProfile() {
      try {
        await this.updateUserProfile(this.userData);
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    try {
      await this.getUserData();
      await this.getCherkassyRegionsData();
      await this.getRegionsData();

      this.userData = { ...this.user };
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
