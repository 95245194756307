<template>
  <form class="auth-form mx-auto" @submit.prevent="">
    <h3 class="auth-form-header">Вхід</h3>
    <div class="grid justify-content-between">
      <div
        :class="[
          'col-12',
          auth.phone && phoneIsValid && showCodeField ? 'sm:col-9' : '',
        ]"
      >
        <div class="input-wrapper p-float-label">
          <InputMask
            type="text"
            id="phone"
            slotChar=""
            v-model="auth.phone"
            :mask="phoneValid.mask"
            :placeholder="phoneValid.mask"
            :class="[
              'input-field auth-field w-full',
              !auth.phone || phoneIsValid ? '' : 'p-invalid',
            ]"
          />
          <label for="phone" class="input-field-label">{{
            `Телефон ${
              phoneIsValid !== null && !phoneIsValid
                ? "(некоректно введені дані)"
                : ""
            }`
          }}</label>
        </div>
      </div>
      <div
        v-if="phoneIsValid && showCodeField"
        :class="[
          'col-12',
          auth.phone && phoneIsValid && showCodeField ? 'sm:col-3' : '',
        ]"
      >
        <div class="input-wrapper p-float-label">
          <InputMask
            type="text"
            id="code"
            slotChar=""
            v-model="auth.code"
            :mask="codeValid.mask"
            :class="[
              'input-field auth-field w-full',
              !auth.code || codeIsValid ? '' : 'p-invalid',
            ]"
          />
          <label for="code" class="input-field-label">Код з SMS</label>
        </div>
      </div>
    </div>
    <div class="auth-form-controls text-center">
      <button
        class="auth-submit-btn w-6"
        :disabled="!phoneIsValid || !codeIsValid"
      >
        Увійти
      </button>
    </div>
  </form>
</template>

<script>
import InputMask from "primevue/inputmask";

import { mapActions } from "vuex";
import { validationCheck } from "@/helpers/validation";

export default {
  components: {
    InputMask,
  },
  props: {
    phoneValid: Object,
    codeValid: Object,
  },
  data() {
    return {
      user_type: 1,
      showCodeField: false,
      auth: {
        phone: null,
        code: null,
      },

      PRESIGNIN_PROCESS: "preSignin",
      SIGNIN_PROCESS: "signin",
    };
  },
  watch: {
    "auth.phone"(newNumber) {
      if (validationCheck(newNumber, this.phoneValid.regex)) {
        this.sendPhone(newNumber);
      } else {
        this.auth.code = null;
      }
    },
    "auth.code"(newCode) {
      if (validationCheck(parseInt(newCode), this.codeValid.regex)) {
        this.signIn();
      }
    },
  },
  methods: {
    ...mapActions(["signinProcess"]),

    async sendPhone(phone) {
      try {
        await this.signinProcess({
          typeProcess: this.PRESIGNIN_PROCESS,
          authPayload: {
            phone: parseInt(phone),
          },
        });

        this.showCodeField = true;
      } catch (e) {
        console.log(e);
      }
    },
    async signIn() {
      try {
        await this.signinProcess({
          typeProcess: this.SIGNIN_PROCESS,
          authPayload: {
            code: parseInt(this.auth.code),
          },
        });

        this.$router.push({ path: "/" });
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    phoneIsValid() {
      return validationCheck(this.auth.phone, this.phoneValid.regex);
    },
    codeIsValid() {
      return validationCheck(parseInt(this.auth.code), this.codeValid.regex);
    },
  },
};
</script>
