import { showErrorNotify, showUserNotify } from "@/helpers/notify";

export default (adminURL, instance) => {
  return {
    getPostsData(payload) {
      return instance
        .post(adminURL + "post/all", payload)
        .catch((error) => showErrorNotify(error));
    },
    createPost(payload) {
      return instance
        .post(adminURL + "post/create", payload, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then(() => showUserNotify("success"))
        .catch((error) => {
          showErrorNotify(error);

          throw error;
        });
    },
    updatePost(id, payload) {
      return instance
        .put(adminURL + `post/${id}`, payload, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then(() => showUserNotify("success"))
        .catch((error) => {
          showErrorNotify(error);

          throw error;
        });
    },
    removePost(id) {
      return instance
        .delete(adminURL + `post/${id}`)
        .then(() => showUserNotify("success"))
        .catch((error) => {
          showErrorNotify(error);

          throw error;
        });
    },
  };
};
