<template>
  <div>
    <Dropdown
      editable
      placeholder="Територіальна громада"
      class="w-full"
      optionValue="id"
      optionLabel="name"
      :options="otg"
      :modelValue="id_region_3"
      @change="(e) => $emit('update:id_region_3', e.value)"
    />
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    Dropdown,
  },
  props: {
    id_region_3: Number,
  },
  emits: ["update:id_region_3"],
  methods: {
    ...mapActions(["getOtgByRegion"]),
  },
  computed: {
    ...mapState({
      otg: (state) => state.regions.otg,
    }),
  },
  async mounted() {
    try {
      await this.getOtgByRegion();
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
