<template>
  <Carousel :wrap-around="true" :autoplay="6000" :items-to-show="1">
    <Slide v-for="slide in sliders" :key="slide">
      <div class="slider">
        <a :href="slide.url">
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-7">
              <div
                v-if="slide.departaments?.tag || slide?.tag"
                class="department-tag slider-tag inline-block"
              >
                {{ slide?.tag || slide.departaments?.tag }}
              </div>
              <div class="slider-content">
                <h1 class="slider-title">
                  {{ slide.title }}
                </h1>
                <p class="slider-description" v-html="slide.short_text"></p>
              </div>
            </div>
            <div
              class="col-12 md:col-6 lg:col-5 flex md:align-items-center justify-content-center"
            >
              <div
                class="slider-img-wrapper flex justify-content-center align-items-center"
              >
                <img :src="siteURL + slide.img" />
              </div>
            </div>
          </div>
        </a>
      </div>
    </Slide>

    <template #addons>
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    sliders: Array,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
};
</script>
