<template>
  <UINavBar />

  <main>
    <section class="content-section news-section">
      <div class="p-container">
        <div class="news-section-header-wrapper">
          <h1 class="section-header m-0">Всі новини</h1>
        </div>
        <div v-if="newsData.rows" class="grid">
          <NewsItem
            v-for="newsItem in newsData?.rows"
            class="col-12 xl:col-6"
            :key="newsItem.id"
            :news="newsItem"
          />
        </div>
      </div>
      <div class="p-5 text-center" v-show="showNextBtn">
        <button class="following-values-btn">
          <span class="following-values-btn-text"> Завантажити ще </span>
        </button>
      </div>
    </section>
  </main>

  <FooterSection />
</template>

<script>
import FooterSection from "@/components/public/content/FooterSection.vue";
import UINavBar from "@/components/public/ui/UINavBar.vue";
import NewsItem from "@/components/public/content/NewsItem.vue";

import filters from "@/config/filters.yml";
import types from "@/config/types.yml";

import { mapActions, mapState } from "vuex";
import { createFilters } from "@/helpers/filters";
import { runScrollPaginator } from "@/helpers/paginator";

export default {
  name: "AllNewsView",
  components: {
    UINavBar,
    FooterSection,
    NewsItem,
  },
  data() {
    return {
      sortPostByDate: {
        field: filters.posts.sortByDate,
        sort: "DESC",
      },
      filterPostByPublished: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      filterByPostTypeID: {
        field: filters.posts.filterByTypeID,
        value: types.post.news,
      },
      filters: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  watch: {
    async "filters.page"() {
      await this.getNews();
    },
  },
  computed: {
    ...mapState({
      newsData: (state) => state.public_posts.newsData,
    }),

    showNextBtn() {
      return (
        this.newsData.total_rows > this.newsData.pages * this.newsData.count &&
        this.newsData.count === this.filters.pageSize
      );
    },
  },
  methods: {
    ...mapActions(["clearPublicPostsData", "getPublicPostsData"]),

    async getNews() {
      try {
        await this.getPublicPostsData({
          postType: types.post.news,
          filters: this.filters,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    try {
      this.filters = createFilters(this.filters, [
        this.filterByPostTypeID,
        this.sortPostByDate,
        this.filterPostByPublished,
      ]);

      await this.getNews();

      runScrollPaginator("following-values-btn", () => this.filters.page++);
    } catch (e) {
      console.log(e);
    }
  },
  unmounted() {
    this.clearPublicPostsData();
  },
};
</script>
