import AuthView from "../views/administration/AuthView.vue";
import WorkspaceView from "../views/administration/WorkspaceView.vue";
import ManagerHomePageView from "../views/administration/manager/ManagerHomePageView.vue";
import EditPostView from "../views/administration/posts/EditPostView.vue";
import PostsView from "../views/administration/posts/PostsView.vue";

const MANAGER_PAGES = [
  {
    path: "pfu-manager",
    name: "pfu-manager-auth",
    meta: { departmentID: 2 },
    component: AuthView,
  },
  {
    path: "cnap-manager",
    name: "cnap-manager-auth",
    meta: { departmentID: 3 },
    component: AuthView,
  },
  {
    path: "ova-manager",
    name: "ova-manager-auth",
    meta: { departmentID: 4 },
    component: AuthView,
  },
  {
    path: "medical-manager",
    name: "medical-manager-auth",
    meta: { departmentID: 5 },
    component: AuthView,
  },
  {
    path: "pravo-manager",
    name: "pravo-manager-auth",
    meta: { departmentID: 6 },
    component: AuthView,
  },
  {
    path: "ck-oda-contact",
    name: "ck-oda-contact-auth",
    meta: { departmentID: 7 },
    component: AuthView,
  },
  {
    path: "dcz-manager",
    name: "dcz-manager-auth",
    meta: { departmentID: 8 },
    component: AuthView,
  },
  {
    path: "education-manager",
    name: "education-manager-auth",
    meta: { departmentID: 9 },
    component: AuthView,
  },
  {
    path: "workspace",
    component: WorkspaceView,
    meta: { requiresAdminAuth: true, isManager: true },
    children: [
      {
        path: "",
        name: "manager-workspace",
        component: ManagerHomePageView,
      },
      {
        path: "posts",
        children: [
          {
            path: "",
            name: "posts",
            component: PostsView,
          },
          {
            path: "create",
            name: "create-post",
            component: EditPostView,
          },
          {
            path: "edit/:id",
            name: "edit-post",
            component: EditPostView,
          },
        ],
      },
    ],
  },
];

export default MANAGER_PAGES;
