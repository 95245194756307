<template>
  <UINavBar />

  <main>
    <section class="content-section compensation-section">
      <div class="p-container">
        <div class="compensation-section-header-wrapper">
          <h1 class="section-header m-0">
            Соціальні гарантії для військовослужбовців, ветеранів, членів їх
            сімей, які надають Територіальні Громади Черкаської області
          </h1>
        </div>
        <form class="compensation-regions-form mt-6">
          <div class="input-wrapper p-float-label mb-3">
            <UIRegionsDropdown
              v-model:idRegion="region"
              :regionsList="regions"
              :placeholder="'Вкажіть населений пункт'"
            />
          </div>
        </form>
        <div class="compensation-information mt-6">
          <div
            v-if="compensationData.rows && compensationData.rows.length > 0"
            class="compensation-information-text single-page-text"
            v-html="compensationData.rows[0]?.text"
          ></div>
          <div
            v-else-if="region && compensationData.rows === null"
            class="compensation-information-text single-page-text"
          >
            <h2>Дані відсутні</h2>
          </div>
          <div v-else class="compensation-information-text single-page-text">
            <p>Почніть вводити назву населеного пункту</p>
          </div>
        </div>
      </div>
    </section>
  </main>

  <FooterSection />
</template>

<script>
import FooterSection from "@/components/public/content/FooterSection.vue";
import UINavBar from "@/components/public/ui/UINavBar.vue";
import UIRegionsDropdown from "@/components/public/ui/UIRegionsDropdown.vue";

import filters from "@/config/filters.yml";
import types from "@/config/types.yml";

import { mapActions, mapState } from "vuex";
import { createFilters } from "@/helpers/filters";

export default {
  name: "CompensationView",
  components: {
    FooterSection,
    UINavBar,
    UIRegionsDropdown,
  },
  data() {
    return {
      region: null,
      filterByPostTypeID: {
        field: filters.posts.filterByTypeID,
        value: types.post.compensation,
      },
      filterByRegion3ID: {
        field: filters.posts.filterByRegion3ID,
        value: null,
      },
      filterPostByPublished: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      sortPostByDate: {
        field: filters.posts.sortByDate,
        sort: "DESC",
      },
      filters: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    ...mapState({
      regions: (state) => state.public_regions.regions,
      compensationData: (state) => state.public_posts.compensationData,
    }),
  },
  watch: {
    region(idRegion) {
      if (idRegion) {
        this.filterByRegion3ID.value = this.regions.find(
          (el) => el.id === idRegion
        ).id_parent;

        this.filters = createFilters(this.filters, [
          this.filterByRegion3ID,
          this.filterByPostTypeID,
          this.filterPostByPublished,
          this.sortPostByDate,
        ]);

        this.getPosts(types.post.compensation);
      }
    },
  },
  methods: {
    ...mapActions(["getRegionsData", "getPublicPostsData"]),

    async getPosts(type) {
      try {
        await this.getPublicPostsData({
          postType: type,
          filters: this.filters,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    try {
      await this.getRegionsData();
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
