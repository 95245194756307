import store from "@/store";

export default {
  getAccessPermission(to, next) {
    const IS_ADMIN_RULE =
      to.matched.some((record) => record.meta.isAdmin) && store.getters.isAdmin;

    const IS_MANAGER_RULE =
      to.matched.some((record) => record.meta.isManager) &&
      !store.getters.isAdmin;

    const IS_PUBLIC_USER_RULE =
      to.matched.some((record) => record.meta.isUser) &&
      !IS_MANAGER_RULE &&
      !IS_ADMIN_RULE;

    if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
      if (store.getters.adminTokenIsValid) {
        if (IS_ADMIN_RULE || IS_MANAGER_RULE) {
          next();
          return;
        } else {
          if (store.getters.isAdmin) {
            next({ name: "admin-posts" });
          } else {
            next({ name: "posts" });
          }
        }
      } else {
        store.dispatch("signOutAdmin");

        next({ path: "/" });
      }
    } else if (to.matched.some((record) => record.meta.requiresPublicAuth)) {
      if (store.getters.publicTokenIsValid) {
        if (IS_PUBLIC_USER_RULE) {
          next();
          return;
        } else {
          next({ name: "public-login-page" });
        }
      } else {
        store.dispatch("signOutUser");

        next({ path: "/" });
      }
    } else {
      next();
    }
  },
};
