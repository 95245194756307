/* eslint-disable no-useless-catch */
import api from "@/api/administration/index";

export default {
  state: {
    otg: null,
  },
  mutations: {
    setOtg(state, payload) {
      state.otg = payload;
    },
  },
  actions: {
    async getOtgByRegion({ commit }) {
      try {
        const otg = (await api.regions.getOtgByRegion(1)).data;

        commit("setOtg", otg);
      } catch (e) {
        throw e;
      }
    },
  },
};
