/* eslint-disable no-useless-catch */
import api from "@/api/public/index";
import axios from "@/api/instance";
import jwt_decode from "jwt-decode";

import { checkRelevanceToken } from "@/helpers/token";

const PUBLIC_TOKEN = process.env.VUE_APP_PUBLIC_TOKEN_NAME;
const PUBLIC_PREREGISTER_TOKEN =
  process.env.VUE_APP_PUBLIC_PREREGISTER_TOKEN_NAME;

export default {
  state: {
    [PUBLIC_TOKEN]: localStorage.getItem(PUBLIC_TOKEN) || "",
  },
  mutations: {
    savePublicToken(state, payload) {
      state[payload.tokenName] = payload.token;
    },
    removePublicToken(state) {
      state[PUBLIC_TOKEN] = "";
      state[PUBLIC_PREREGISTER_TOKEN] = "";
    },
  },
  actions: {
    async signinProcess({ commit }, payload) {
      try {
        let token = "";
        switch (payload.typeProcess) {
          case "preSignin":
            token = (await api.auth.preSignin(payload.authPayload)).data.token;
            break;
          case "signin":
            token = (await api.auth.signIn(payload.authPayload)).data.token;
            localStorage.setItem(PUBLIC_TOKEN, token);

            break;
        }

        axios.defaults.headers.common.Authorization = `Bearer ${token}`;

        commit("savePublicToken", { tokenName: PUBLIC_TOKEN, token: token });
      } catch (e) {
        throw e;
      }
    },
    async signOutUser({ commit }) {
      try {
        commit("removePublicToken");
        await api.auth.signOut();

        localStorage.removeItem(PUBLIC_TOKEN);
        delete axios.defaults.headers.common.Authorization;
      } catch (e) {
        throw e;
      }
    },
    async getRegistrationToken({ commit }, payload) {
      try {
        let token = "";
        switch (payload.typeProcess) {
          case "preRegister":
            token = (
              await api.registeration.registrationProfile(
                payload.registerPayload
              )
            ).data.token;

            localStorage.setItem(PUBLIC_PREREGISTER_TOKEN, token);
            commit("savePublicToken", {
              tokenName: PUBLIC_PREREGISTER_TOKEN,
              token: token,
            });

            break;
          case "register":
            token = (
              await api.registeration.confirmRegistrationPhone(
                payload.registerPayload
              )
            ).data.token;

            localStorage.removeItem(PUBLIC_PREREGISTER_TOKEN);
            localStorage.setItem(PUBLIC_TOKEN, token);
            commit("savePublicToken", {
              tokenName: PUBLIC_TOKEN,
              token: token,
            });
            break;
        }

        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    publicTokenIsValid: (state) => {
      if (state[PUBLIC_TOKEN]) {
        return (
          checkRelevanceToken(state[PUBLIC_TOKEN]) &&
          Boolean(jwt_decode(state[PUBLIC_TOKEN]).name)
        );
      }

      return false;
    },

    publicTokenData: (state) => {
      if (state[PUBLIC_TOKEN]) {
        return jwt_decode(state[PUBLIC_TOKEN]);
      }
    },
  },
};
