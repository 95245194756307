/* eslint-disable no-useless-catch */
import api from "@/api/administration/index";
import axios from "@/api/instance";
import jwt_decode from "jwt-decode";

import { checkRelevanceToken } from "@/helpers/token";

const TOKEN = process.env.VUE_APP_TOKEN_NAME;

export default {
  state: {
    [TOKEN]: localStorage.getItem(TOKEN) || "",
  },
  mutations: {
    saveAdminToken(state, payload) {
      state[TOKEN] = payload.token;
    },
    removeAdminToken(state) {
      state[TOKEN] = "";
    },
  },
  actions: {
    async signin({ commit }, user) {
      try {
        const token = (await api.auth.signIn(user)).data.token;
        localStorage.setItem(TOKEN, token);
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;

        commit("saveAdminToken", { token: token });
      } catch (e) {
        throw e;
      }
    },
    async signOutAdmin({ commit }) {
      try {
        commit("removeAdminToken");
        await api.auth.signOut();

        delete axios.defaults.headers.common.Authorization;
        localStorage.removeItem(TOKEN);
      } catch (e) {
        throw e;
      }
    },
  },
  getters: {
    adminToken: (state) => state[TOKEN],
    adminTokenIsValid: (state) => checkRelevanceToken(state[TOKEN]),
    isAdmin: (state) => {
      if (state[TOKEN]) {
        return jwt_decode(state[TOKEN]).departament === 1;
      }
    },
    userFullName: (state) => {
      if (state[TOKEN]) {
        return jwt_decode(state[TOKEN]).client;
      }
    },
    managerDepartmentID: (state) => {
      if (state[TOKEN]) {
        return jwt_decode(state[TOKEN]).departament;
      }
    },
  },
};
