<template>
  <BreadcrumbsList :breadcrumbs="breadcrumbs" />
  <div class="content mt-3">
    <PostsTable
      :posts="posts"
      :title="'Публікації'"
      :filters="filters"
      @getItemsByFilter="
        async (filter) => {
          createPostFilters(filter);

          await getPosts();
        }
      "
      @clearFilters="
        async () => {
          filters = clearFiltersList('post_filters');
          createPostFilters();

          await getPosts();
        }
      "
    />
  </div>
  <div class="content mt-3 p-2">
    <TablePaginator
      :rows="postsData.count"
      :totalRows="postsData.total_rows"
      @getItemsByPage="
        async (event) => {
          filters = changePageFilter(filters, event);
          createPostFilters();

          await getPosts();
        }
      "
    />
  </div>
</template>

<script>
import BreadcrumbsList from "@/components/admin/ui/BreadcrumbsList.vue";
import TablePaginator from "@/components/admin/ui/TablePaginator.vue";
import PostsTable from "@/components/admin/posts/PostsTable.vue";

import filters from "@/config/filters.yml";
import types from "@/config/types.yml";

import { mapActions, mapGetters, mapState } from "vuex";
import {
  createFilters,
  changePageFilter,
  clearFiltersList,
  savingFiltersInStorage,
} from "@/helpers/filters";

const SIDEBAR_TYPE_POST = types.post.sidebar;
const POST_TYPE_POST = types.post.post;
const POSTS_FILTERS_NAME = "post_filters";

export default {
  name: "PostsView",
  components: {
    BreadcrumbsList,
    TablePaginator,
    PostsTable,
  },
  data() {
    return {
      SIDEBAR_TYPE_POST,
      POST_TYPE_POST,
      POSTS_FILTERS_NAME,

      breadcrumbs: [{ label: "Публікації" }],
      filterPostsByDepartmentsID: {
        field: filters.posts.filterByDepartmentID,
        value: null,
      },
      sortByPosition: {
        field: filters.posts.sortByPosition,
        sort: "ASC",
      },
      sortPostByDate: {
        field: filters.posts.sortByDate,
        sort: "DESC",
      },
      filters: {
        page: 1,
        pageSize: 20,
      },

      search: "",
    };
  },
  computed: {
    ...mapState({
      postsData: (state) => state.posts.postsData,
    }),

    ...mapGetters(["managerDepartmentID", "isAdmin"]),

    posts() {
      if (this.postsData.rows) {
        let posts = this.postsData.rows.filter((item) => {
          return (
            item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });

        return posts;
      }

      return [];
    },
  },
  methods: {
    ...mapActions(["getPostsData", "clearPostsData", "getDepartmentsData"]),

    clearFiltersList,
    changePageFilter,
    createPostFilters(filter) {
      let filtered = [];

      if (filter) {
        if (
          filter.field === filters.posts.filterByTypeID &&
          (filter.value === this.SIDEBAR_TYPE_POST ||
            filter.value === this.POST_TYPE_POST)
        ) {
          let sortIndex = this.filters.filtered.findIndex((item) => item.sort);
          this.filters.filtered[sortIndex] = this.sortByPosition;

          filtered = [filter];
        } else {
          filtered = [filter, this.sortPostByDate];
        }
      } else {
        filtered = [this.sortPostByDate];
      }

      this.filters = createFilters(this.filters, filtered);
      savingFiltersInStorage(this.filters, POSTS_FILTERS_NAME);
    },
    async getPosts() {
      this.checkIsNotAdmin();

      await this.getPostsData(this.filters);
    },
    checkIsNotAdmin() {
      if (!this.isAdmin) {
        this.filterPostsByDepartmentsID.value = this.managerDepartmentID;

        if (
          !this.filters.filtered ||
          !this.filters.filtered?.find(
            (el) => el.field === this.filterPostsByDepartmentsID.field
          )
        ) {
          this.filters = createFilters(this.filters, [
            this.filterPostsByDepartmentsID,
          ]);
        } else {
          let departmentFilterID = this.filters.filtered.findIndex(
            (el) => el.field === this.filterPostsByDepartmentsID.field
          );

          if (departmentFilterID !== -1) {
            this.filters.filtered[departmentFilterID] =
              this.filterPostsByDepartmentsID;
          }
        }

        savingFiltersInStorage(this.filters, POSTS_FILTERS_NAME);
      }
    },
  },
  async mounted() {
    try {
      if (this.isAdmin) {
        await this.getDepartmentsData({
          pageSize: -1,
          filtered: [],
        });
      }

      if (localStorage.getItem(POSTS_FILTERS_NAME)) {
        this.filters = JSON.parse(localStorage.getItem(POSTS_FILTERS_NAME));
      } else {
        this.createPostFilters();
      }

      await this.getPosts();
    } catch (e) {
      console.log(e);
    }
  },
  unmounted() {
    this.clearPostsData();
  },
};
</script>
