/* eslint-disable no-useless-catch */
import api from "@/api/public/index";

export default {
  state: {
    cherkassyRegions: null,
    regions: null,
  },
  mutations: {
    setCherkassyRegions(state, payload) {
      state.cherkassyRegions = payload;
    },
    setRegions(state, payload) {
      state.regions = payload;
    },
  },
  actions: {
    async getCherkassyRegionsData({ commit }) {
      try {
        const cherkassyRegions = (await api.regions.getCherkassyRegions()).data;

        commit("setCherkassyRegions", cherkassyRegions);
      } catch (e) {
        throw e;
      }
    },
    async getRegionsData({ commit }) {
      try {
        const regions = (await api.regions.getRegions()).data;

        commit("setRegions", regions);
      } catch (e) {
        throw e;
      }
    },
  },
};
