<template>
  <div class="card service-card h-full">
    <div v-if="service.isQuestionnaire" class="shape shape-yellow"></div>
    <div v-if="service.isQuestionnaire" class="shape shape-blue"></div>

    <div class="h-full relative">
      <div class="card-header service-card-header flex align-items-center">
        <div
          class="card-header-img service-card-header-img flex align-items-center justify-content-center"
        >
          <img
            v-if="service.isQuestionnaire"
            class="service-card-logo"
            src="@/assets/img/content/f-i.svg"
          />
          <img
            v-if="service.logo && !service.isQuestionnaire"
            class="service-card-logo"
            :src="siteURL + service.logo"
          />
        </div>
        <h3 class="card-header-text card-header service-card-header-text">
          {{ service.title }}
        </h3>
      </div>
      <hr class="w-full" />
      <div class="card-body service-card-body flex-grow-1">
        <span
          v-if="service.isQuestionnaire"
          class="card-body-text service-card-body-text"
          v-html="service.short_text"
        >
        </span>
        <span v-else class="card-body-text service-card-body-text">
          {{ service.short_text }}
        </span>
      </div>
      <div
        class="card-footer service-card-footer text-right absolute bottom-0 right-0"
      >
        <img
          class="link-arrow"
          src="@/assets/img/content/arrow.svg"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    service: Object,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
};
</script>
