<template>
  <nav class="navbar sticky">
    <div
      class="navbar-container p-container flex lg:justify-content-between lg:align-items-center"
    >
      <div class="logo navbar-logo lg:w-full">
        <a class="flex" href="/">
          <img
            class="logo-img navbar-logo-img float-right"
            src="@/assets/img/content/ck_logo.svg"
          />
          <div
            class="logo-text navbar-logo-text flex flex-column justify-content-center"
          >
            <span class="block logo-text-second navbar-logo-text-second">
              Платформа ветерана
            </span>
            <span class="block logo-text-main navbar-logo-text-main">
              Черкаський вимір
            </span>
          </div>
        </a>
      </div>
      <div
        class="navbar-panel lg:h-full lg:w-full lg:flex lg:align-items-center"
      >
        <input type="checkbox" v-model="showMobileMenu" />
        <div class="hamburger-lines flex flex-column justify-content-between">
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </div>
        <div class="navbar-panel-wrapper lg:w-full flex justify-content-end">
          <a
            href="https://t.me/ukrveteran_ck"
            class="telegram-link flex flec-column align-items-end p-3"
            target="_blank"
          >
            <span class="flex align-items-center">Читайте нас в</span>
          </a>
          <div class="navbar-user-panel py-3 relative text-right">
            <UIDesktopUserMenu :userMenu="userMenu" @signout="signout()" />
          </div>
        </div>
      </div>
    </div>
    <div
      :class="[
        'navbar-menu hidden lg:block',
        showMobileMenu ? 'mobile-nav-menu block' : 'navbar-menu ',
      ]"
    >
      <div
        class="p-container relative flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between"
      >
        <div class="navbar-user-panel lg:hidden">
          <UIMobileUserMenu @signout="signout()" />
        </div>
        <ul class="navbar-menu-list flex-order-2 lg:flex lg:flex-order-1">
          <UIMenuItem
            v-for="item in createMenuList()"
            v-model:showSubmenuPanel="showSubmenuPanel"
            :key="item.id"
            :menuItem="item"
            :showMobileMenu="showMobileMenu"
          />
        </ul>
        <div
          class="navbar-search-wrapper my-4 flex-order-1 lg:flex-order-2 lg:my-0"
        >
          <UISearchForm
            class=""
            :showMobileMenu="showMobileMenu"
            @showMobileMenu="(show) => (showMobileMenu = show)"
          />
        </div>
        <a
          href="https://t.me/ukrveteran_ck"
          class="flex-order-3 telegram-link telegram-link--mobile flex flec-column align-items-end lg:hidden"
          target="_blank"
        >
          <span class="flex align-items-center">Читайте нас в</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import UISearchForm from "./UISearchForm.vue";
import UIMenuItem from "./UIMenuItem.vue";
import UIDesktopUserMenu from "./UIDesktopUserMenu.vue";
import UIMobileUserMenu from "./UIMobileUserMenu.vue";

import { mapActions, mapGetters, mapState } from "vuex";

import { createFilters } from "@/helpers/filters";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

export default {
  components: {
    UISearchForm,
    UIMenuItem,
    UIDesktopUserMenu,
    UIMobileUserMenu,
  },
  data() {
    return {
      search: "",
      show: false,
      showMobileMenu: false,
      showSubmenuPanel: null,
      showUserMenu: false,
      userMenu: [
        { label: "Особисті дані", name: "user-profile" },
        { label: "Вихід", isLogout: true },
      ],
      menu: {
        home: {
          id: 1,
          name: "Новини",
          url: "/news",
        },
        departmentMenuItem: {
          id: 2,
          name: "Органи влади",
          url: null,
          children: [],
        },
        serviceMenuItem: {
          id: 3,
          name: "Сервіси",
          url: null,
          children: [],
        },
        otherMenuItem: {
          id: 4,
          name: "Інше",
          url: null,
          children: [],
        },
        compensation: {
          id: 5,
          name: "Пільги ТГ",
          url: "compensation",
        },
      },
      departmentTypes: [
        types.department.type.department.id,
        types.department.type.service.id,
        types.department.type.other.id,
      ],
      filterByDepartmentTypeID: {
        field: filters.departaments.filterByTypeID,
        value: 0,
      },
      sortDepartmentByPosition: {
        field: filters.departaments.sortByPosition,
        sort: "ASC",
      },
      filters: {
        pageSize: -1,
      },
    };
  },
  computed: {
    ...mapState({
      departmentsAllData: (state) => state.public_departments.departmentsData,
      services: (state) => state.public_departments.services,
      departments: (state) => state.public_departments.departments,
      other: (state) => state.public_departments.other,
    }),

    ...mapGetters(["publicTokenIsValid", "publicTokenData"]),
  },
  watch: {
    showSubmenuPanel(newValue) {
      if (newValue && !this.showMobileMenu) {
        this.submenuListener();
      }
    },
    showMobileMenu(newValue) {
      this.overflowSwitcher(newValue);
    },
  },
  methods: {
    ...mapActions(["getPublicDepartmentsData", "signOutUser"]),

    overflowSwitcher(onHidden) {
      let body = document.getElementsByTagName("body")[0];

      body.style.overflow = onHidden ? "hidden" : "auto";
    },
    createMenuList() {
      this.menu.departmentMenuItem.children = this.departments;
      this.menu.serviceMenuItem.children = this.services;
      this.menu.otherMenuItem.children = this.other;

      return Object.keys(this.menu).map((key) => this.menu[key]);
    },
    submenuListener() {
      document.addEventListener("click", (ev) => {
        if (
          !ev.target.parentElement.classList.value
            .split(" ")
            .some(
              (el) =>
                el === "submenu-wrapper" ||
                el === "navbar-menu-item" ||
                el === "submenu-list"
            )
        ) {
          this.showSubmenuPanel = null;
        }
      });
    },
    async signout() {
      try {
        await this.signOutUser();
        if (this.$route.name === "home-page") {
          this.$router.go();
        } else {
          this.$router.push({ path: "/" });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    try {
      if (!this.departments || !this.services || !this.other) {
        this.departmentTypes.forEach(async (type) => {
          this.filterByDepartmentTypeID.value = type;

          await this.getPublicDepartmentsData({
            departmentType: type,
            filters: createFilters(this.filters, [
              this.filterByDepartmentTypeID,
              this.sortDepartmentByPosition,
            ]),
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  unmounted() {
    this.overflowSwitcher(false);
  },
};
</script>
