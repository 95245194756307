/* eslint-disable no-useless-catch */
import api from "@/api/administration/index";
import api_public from "@/api/public/index";

const DEFAULT_DATA = {
  count: 0,
  pages: 0,
  rows: [],
  total_rows: 0,
};

export default {
  state: {
    postsData: DEFAULT_DATA,
    postTypes: DEFAULT_DATA,
  },
  mutations: {
    setPostsData(state, payload) {
      state.postsData = payload;
    },
    setPostTypesData(state, payload) {
      state.postTypes = payload;
    },
    setDefaultPostsData(state) {
      state.postsData = DEFAULT_DATA;
      state.postTypes = DEFAULT_DATA;
    },
  },
  actions: {
    async getPostsData({ commit }, payload) {
      try {
        const postsData = (await api.posts.getPostsData(payload)).data;

        commit("setPostsData", postsData);
      } catch (e) {
        throw e;
      }
    },
    async getPostTypesData({ commit }) {
      try {
        const postTypes = (await api_public.posts.getPostTypesData()).data;

        commit("setPostTypesData", postTypes);
      } catch (e) {
        throw e;
      }
    },
    async createPostData({ dispatch }, payload) {
      try {
        await api.posts.createPost(payload.post_data);

        dispatch("getPostsData", payload.filters);
      } catch (e) {
        throw e;
      }
    },
    async updatePostData({ dispatch }, payload) {
      try {
        await api.posts.updatePost(payload.id, payload.post_data);

        dispatch("getPostsData", payload.filters);
      } catch (e) {
        throw e;
      }
    },
    async removePostData({ dispatch }, payload) {
      try {
        await api.posts.removePost(payload.id);

        dispatch("getPostsData", payload.filters);
      } catch (e) {
        throw e;
      }
    },
    clearPostsData({ commit }) {
      commit("setDefaultPostsData");
    },
  },
};
