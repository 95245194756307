<template>
  <form class="auth-form mx-auto" @submit.prevent="">
    <h3 class="auth-form-header">Реєстрація</h3>
    <div
      class="user-types flex flex-column md:flex-row gap-2 align-items-center justify-content-center"
    >
      <button
        :class="[
          'user-type-btn w-full',
          userData.type_person === 1 ? 'active' : '',
        ]"
        @click="userData.type_person = 1"
      >
        Я військовий, ветеран
      </button>
      <button
        :class="[
          'user-type-btn w-full',
          userData.type_person === 2 ? 'active' : '',
        ]"
        @click="
          userData.type_person = 2;
          userData.nic_name = null;
        "
      >
        Я родич військового, ветерана
      </button>
    </div>
    <div class="hidden md:block backing"></div>
    <h4 class="auth-form-subtitle text-center">Дані для входу</h4>
    <div class="input-wrapper p-float-label mb-3">
      <InputMask
        type="text"
        id="phone"
        slotChar=""
        v-model="userData.phone"
        :mask="phoneValid.mask"
        :placeholder="phoneValid.mask"
        :class="[
          'input-field auth-field w-full',
          phoneIsValid ? '' : 'p-invalid',
        ]"
      />
      <label for="phone" class="input-field-label">
        {{
          `Телефон ${
            phoneIsValid !== null
              ? !phoneIsValid
                ? "(некоректно введені дані)"
                : ""
              : "(обов'язково)"
          }`
        }}
      </label>
    </div>
    <h4 class="auth-form-subtitle text-center">Особисті дані</h4>
    <div class="input-wrapper p-float-label mb-3">
      <InputText
        type="text"
        id="surname"
        v-model="userData.surname"
        :class="[
          'input-field auth-field w-full',
          surnameIsValid ? '' : 'p-invalid',
        ]"
      />
      <label for="surname" class="input-field-label">
        {{
          `Прізвище ${
            surnameIsValid !== null
              ? !surnameIsValid
                ? "(некоректно введені дані)"
                : ""
              : "(обов'язково)"
          }`
        }}
      </label>
    </div>
    <div class="input-wrapper p-float-label mb-3">
      <InputText
        type="text"
        id="name"
        v-model="userData.name"
        :class="[
          'input-field auth-field w-full',
          nameIsValid ? '' : 'p-invalid',
        ]"
      />
      <label for="name" class="input-field-label">
        {{
          `Ім’я ${
            nameIsValid !== null
              ? !nameIsValid
                ? "(некоректно введені дані)"
                : ""
              : "(обов'язково)"
          }`
        }}
      </label>
    </div>
    <div class="input-wrapper p-float-label mb-3">
      <InputText
        type="text"
        id="lastname"
        v-model="userData.lastname"
        :class="[
          'input-field auth-field w-full',
          !lastnameIsValid && lastnameIsValid !== null ? 'p-invalid' : '',
        ]"
      />
      <label for="lastname" class="input-field-label">
        {{
          `По батькові  ${
            !lastnameIsValid && lastnameIsValid !== null
              ? "(некоректно введені дані)"
              : ""
          }`
        }}
      </label>
    </div>
    <div
      v-show="userData.type_person === 1"
      class="input-wrapper p-float-label mb-3"
    >
      <InputText
        type="text"
        id="callsign"
        class="input-field auth-field w-full"
        v-model="userData.nic_name"
      />
      <label for="callsign" class="input-field-label">Позивний</label>
    </div>
    <div class="input-wrapper p-float-label mb-3">
      <InputText
        type="email"
        id="email"
        v-model="userData.email"
        class="input-field auth-field w-full"
      />
      <label for="email" class="input-field-label"> E-Mail </label>
    </div>
    <h4 class="auth-form-subtitle text-center">Місце реєстрації</h4>
    <div class="input-wrapper p-float-label mb-3">
      <Dropdown
        disabled
        id="area"
        class="input-dropdown w-full"
        optionLabel="name"
        optionValue="id"
        v-model="cherkassyArea"
        :options="cherkassyRegions"
      />
      <label for="area" class="input-field-label">Область</label>
    </div>
    <div class="input-wrapper p-float-label mb-3">
      <UIRegionsDropdown
        required
        showClear
        v-model:idRegion="userData.id_region"
        :regionsList="regions"
        :placeholder="`Населений пункт (обов'язково)`"
      />
    </div>
    <div class="input-wrapper p-float-label mb-3">
      <InputText
        type="text"
        id="address"
        class="input-field auth-field w-full"
        v-model="userData.address"
      />
      <label for="homenumber" class="input-field-label">Адреса</label>
    </div>
    <div class="auth-form-controls text-center">
      <button
        class="auth-submit-btn w-full md:w-6"
        :disabled="!isValidForm"
        @click="registration()"
      >
        Зареєструватись
      </button>
    </div>
  </form>
  <RegisterCodeForm :visible="showCodeForm" :codeValid="codeValid" />
</template>

<script>
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import Dropdown from "primevue/dropdown";
import RegisterCodeForm from "./RegisterCodeForm.vue";
import UIRegionsDropdown from "../ui/UIRegionsDropdown.vue";

import { mapActions, mapState } from "vuex";
import { validationCheck } from "@/helpers/validation";

const SURNAME_REGEX =
  /^([a-zA-Zа-яА-ЯіІїЇєЄ])+(([-])|(\s?[-]\s?))?([a-zA-Zа-яА-ЯіІїЇєЄ])+$/;
const ONLY_LETTERS_REGEX = /^([a-zA-Zа-яА-ЯіІїЇєЄ]){2,}$/;

export default {
  components: {
    InputText,
    InputMask,
    Dropdown,
    RegisterCodeForm,
    UIRegionsDropdown,
  },
  props: {
    phoneValid: Object,
    codeValid: Object,
  },
  data() {
    return {
      SURNAME_REGEX,
      ONLY_LETTERS_REGEX,
      userData: {
        phone: null,
        email: null,
        surname: null,
        name: null,
        lastname: null,
        nic_name: null,
        type_person: 1,
        id_region: null,
        address: null,
      },
      isValidCode: false,
      code: null,

      //temp area
      cherkassyArea: 1,

      showCodeForm: false,

      PREREGISTER_PROCESS: "preRegister",
      HAS_BEEN_SENT: "code already has been sent",
    };
  },
  watch: {
    showCodeForm(val) {
      if (val) {
        this.closeForm();
      }
    },
  },
  computed: {
    ...mapState({
      regions: (state) => state.public_regions.regions,
      cherkassyRegions: (state) => state.public_regions.cherkassyRegions,
    }),

    isValidForm() {
      return (
        this.surnameIsValid &&
        this.phoneIsValid &&
        this.nameIsValid &&
        this.userData.id_region &&
        this.userData.type_person
      );
    },
    surnameIsValid() {
      return validationCheck(this.userData.surname, this.SURNAME_REGEX);
    },
    nameIsValid() {
      return validationCheck(this.userData.name, this.ONLY_LETTERS_REGEX);
    },
    lastnameIsValid() {
      return validationCheck(this.userData.lastname, this.ONLY_LETTERS_REGEX);
    },
    phoneIsValid() {
      return validationCheck(this.userData.phone, this.phoneValid.regex);
    },
  },
  methods: {
    ...mapActions([
      "getCherkassyRegionsData",
      "getRegionsData",
      "getRegistrationToken",
    ]),

    async registration() {
      this.userData.phone = parseInt(this.userData.phone);

      try {
        await this.getRegistrationToken({
          typeProcess: this.PREREGISTER_PROCESS,
          registerPayload: this.userData,
        });

        this.showCodeForm = true;
      } catch (e) {
        console.log(e);
      }
    },
    closeForm() {
      window.addEventListener("click", (e) => {
        if (e.target.classList.contains("p-component-overlay")) {
          this.showCodeForm = false;
        }
      });
    },
  },
  async mounted() {
    try {
      await this.getCherkassyRegionsData();
      await this.getRegionsData();
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
