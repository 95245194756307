<template>
  <li
    :class="[
      'flex navbar-menu-item justify-content-center',
      showMobileMenu ? 'flex-column' : '',
      menuItem.children ? 'navbar-menu-item-list' : 'navbar-menu-item-link',
    ]"
  >
    <button
      v-if="menuItem.children"
      class="w-full flex justify-content-between align-items-center lg:block navbar-menu-list-btn navbar-menu-item-title"
      @click="
        showSubmenuPanel === menuItem.id
          ? $emit('update:showSubmenuPanel', null)
          : $emit('update:showSubmenuPanel', menuItem.id)
      "
    >
      {{ menuItem.name }}
      <i class="menu-list-icon"></i>
    </button>
    <div
      v-if="menuItem.children"
      :class="[
        showMobileMenu ? 'mobile-submenu-wrapper' : 'navbar-submenu-wrapper',
        showMobileMenu && showSubmenuPanel === menuItem.id ? 'show' : '',
      ]"
    >
      <div
        :class="[
          'grid submenu-wrapper',
          showSubmenuPanel === menuItem.id ? 'show' : 'hidden',
        ]"
      >
        <div class="p-container">
          <ul class="grid submenu-list">
            <li
              v-for="item in menuItem.children"
              class="col-12 lg:col-4 xl:col-3"
              :key="item"
            >
              <a :href="'/' + item.url">
                <span>
                  {{ item.title }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <a v-else class="flex align-items-center" :href="menuItem.url">
      <span class="navbar-menu-item-title">{{ menuItem.name }}</span>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    menuItem: Object,
    showMobileMenu: Boolean,
    showSubmenuPanel: Number,
  },
  emits: ["update:showSubmenuPanel"],
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
};
</script>
