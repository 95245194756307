<template>
  <form class="py-2" @submit.prevent="signIn">
    <div class="field">
      <div class="p-float-label">
        <InputText
          id="login"
          v-model="login"
          type="text"
          :class="login ? '' : 'p-invalid'"
        />
        <label for="login">Логін</label>
      </div>
    </div>
    <div class="field">
      <div class="p-float-label">
        <Password
          id="password"
          v-model="password"
          :feedback="false"
          :class="password ? '' : 'p-invalid'"
        />
        <label for="password">Пароль</label>
      </div>
    </div>
    <Button
      type="submit"
      label="Увійти"
      class="mt-2"
      :disabled="!login || !password"
    />
  </form>
</template>

<script>
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";

import { mapActions } from "vuex";

export default {
  components: {
    Button,
    InputText,
    Password,
  },
  data() {
    return {
      login: null,
      password: null,
    };
  },
  methods: {
    ...mapActions(["signin"]),

    async signIn() {
      try {
        await this.signin({
          login: this.login,
          password: this.password,
        });

        this.$route.meta.isAdmin
          ? this.$router.push({ name: "admin-posts" })
          : this.$router.push({ name: "posts" });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
