<template>
  <router-link
    class="hero-card text-center"
    :to="`${hero.departaments.url}/${hero.url}`"
  >
    <div class="hero-img" :style="createBgImg(siteURL, hero.img)"></div>
    <div class="hero-fullname">
      <p class="hero hero-name">{{ hero.title }}</p>
    </div>
    <p class="hero-link">Історія героя</p>
  </router-link>
</template>

<script>
import { createBgImg } from "@/helpers/styles";

export default {
  props: {
    hero: Object,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    createBgImg,
    getImgUrl(img) {
      return require("../../../assets/img/heroes/" + img);
    },
  },
};
</script>
