/* eslint-disable no-useless-catch */
import api from "@/api/public/index";
import types from "@/config/types.yml";

const DEFAULT_DATA = {
  count: 0,
  pages: 0,
  rows: [],
  total_rows: 0,
};

export default {
  state: {
    postsData: DEFAULT_DATA,
    sidebarData: DEFAULT_DATA,
    newsData: DEFAULT_DATA,
    slidersData: DEFAULT_DATA,
    pageData: DEFAULT_DATA,
    compensationData: DEFAULT_DATA,
  },
  mutations: {
    setPublicPostsData(state, payload) {
      switch (payload.postType) {
        case types.post.post:
          state.postsData = {
            count: payload.data.count,
            pages: payload.data.pages,
            rows: payload.data.rows
              ? state.postsData.rows.concat(payload.data.rows)
              : state.postsData.rows,
            total_rows: payload.data.total_rows,
          };

          console.log(state.postsData);
          break;
        case types.post.news:
          if (payload.pageType === 0) {
            state.newsData = payload.data;
            if (payload.data.rows && payload.data.rows.length > 4) {
              state.newsData.rows = payload.data.rows.slice(0, 4);
            }
          } else {
            state.newsData = {
              count: payload.data.count,
              pages: payload.data.pages,
              rows: payload.data.rows
                ? state.postsData.rows.concat(payload.data.rows)
                : state.newsData.rows,
              total_rows: payload.data.total_rows,
            };
          }
          break;
        case types.post.sidebar:
          state.sidebarData = payload.data;
          break;
        case types.post.slider:
          state.slidersData = payload.data;
          break;
        case types.post.page:
          state.pageData = payload.data;
          break;
        case types.post.compensation:
          state.compensationData = payload.data;
          break;
        default:
          break;
      }
    },
    setDefaultPublicPostsData(state) {
      state.postsData = DEFAULT_DATA;
      state.newsData = DEFAULT_DATA;
      state.sidebarData = DEFAULT_DATA;
      state.slidersData = DEFAULT_DATA;
      state.compensationData = DEFAULT_DATA;
      state.pageData = DEFAULT_DATA;
    },
  },
  actions: {
    async getPublicPostsData({ commit }, payload) {
      try {
        const postsData = (await api.posts.getPostsDataByType(payload.filters))
          .data;

        commit("setPublicPostsData", {
          pageType: payload.departmentID,
          postType: payload.postType,
          data: postsData,
          concat: payload.concat,
        });
      } catch (e) {
        throw e;
      }
    },
    async getNewsData({ commit }, payload) {
      try {
        const newsData = (
          await api.posts.getPostsDataByType(payload, types.post.news)
        ).data;

        commit("setPublicPostsData", {
          pageType: payload,
          newsData,
        });
      } catch (e) {
        throw e;
      }
    },
    clearPublicPostsData({ commit }) {
      commit("setDefaultPublicPostsData");
    },
  },
  getters: {
    singlePage: (state) => {
      if (state.pageData.rows) {
        return state.pageData.rows[0];
      }
    },
  },
};
