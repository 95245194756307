<template>
  <UINavBar />

  <header
    class="header department-header"
    :style="`${
      createBgColor(department?.bg_color) +
      createBgImg(siteURL, department?.bg_img)
    }`"
  >
    <div class="p-container relative">
      <div
        class="department-header-wrapper m-auto flex flex-column lg:flex-row lg:gap-4 align-items-center justify-content-center"
      >
        <div class="department-header-img">
          <img
            v-if="department?.img"
            class="department-header-logo"
            :src="siteURL + department?.img"
          />
        </div>
        <div
          class="department-header-text"
          :style="`${createColor(department?.text_color)}`"
        >
          <h1
            :class="[
              'department-header-title text-center ',
              department?.img ? 'lg:text-left' : '',
            ]"
          >
            {{ department?.title }}
          </h1>
          <p class="p-0 department-header-description text-center">
            {{ department?.short_text }}
          </p>
        </div>
      </div>
      <div
        class="view-info absolute left-0 bottom-0"
        :style="`${createColor(department?.text_color)}`"
      >
        <UIViewEye class="view-info-eye" :color="department?.text_color" />
        <span class="view-info-counter">
          {{ department?.view_count }}
        </span>
      </div>
    </div>
  </header>
  <main>
    <section>
      <div class="p-container">
        <div class="department-services">
          <div class="grid">
            <div v-if="postsData.rows" class="col-12 lg:col-8 lg:pr-4">
              <AccordionPanel
                class="department-posts"
                :items="postsData.rows"
              />
              <div class="p-5 text-center" v-show="showNextBtn">
                <button class="following-values-btn">
                  <span class="following-values-btn-text">
                    Завантажити ще
                  </span>
                </button>
              </div>
            </div>
            <div v-if="sidebarData.rows" class="col-12 lg:col-4">
              <SideBar class="mt-4" :sidebarItems="sidebarData.rows" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <FooterSection />
</template>

<script>
import UINavBar from "@/components/public/ui/UINavBar.vue";
import SideBar from "@/components/public/content/SideBar.vue";
import AccordionPanel from "@/components/public/content/AccordionPanel.vue";
import FooterSection from "@/components/public/content/FooterSection.vue";
import UIViewEye from "@/components/public/ui/UIViewEye.vue";

import filters from "@/config/filters.yml";
import types from "@/config/types.yml";

import { mapActions, mapState } from "vuex";
import { createFilters } from "@/helpers/filters";
import { createBgColor, createBgImg, createColor } from "@/helpers/styles";
import { runScrollPaginator } from "@/helpers/paginator";

export default {
  name: "DepartmentView",
  components: {
    UINavBar,
    SideBar,
    AccordionPanel,
    FooterSection,
    UIViewEye,
  },
  data() {
    return {
      filterByDepartmentURL: {
        field: filters.departaments.filterByURL,
        value: null,
      },
      filterByDepartmentID: {
        field: filters.posts.filterByDepartmentID,
        value: null,
      },
      filterByPostTypeID: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      filterPostByPublished: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      sortPostByPosition: {
        field: filters.posts.sortByPosition,
        sort: "ASC",
      },
      postTypes: [types.post.post, types.post.sidebar],
      filters: {
        page: 1,
        pageSize: 10,
      },
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
  watch: {
    async "filters.page"() {
      this.filterByPostTypeID.value = types.post.post;

      await this.getPosts(types.post.post);
    },
  },
  computed: {
    ...mapState({
      postsData: (state) => state.public_posts.postsData,
      sidebarData: (state) => state.public_posts.sidebarData,
      department: (state) => state.public_departments.department,
    }),

    showNextBtn() {
      return (
        this.postsData.total_rows >
          this.postsData.pages * this.postsData.count &&
        this.postsData.count === this.filters.pageSize
      );
    },
  },
  methods: {
    ...mapActions([
      "getPublicDepartmentData",
      "getPublicPostsData",
      "clearPublicPostsData",
      "clearPublicDepartmentsData",
    ]),
    createBgColor,
    createBgImg,
    createColor,

    async getPostByPage() {
      this.filters.page++;
      this.filterByPostTypeID.value = types.post.post;

      await this.getPosts(types.post.post);
    },
    async getPosts(type) {
      try {
        await this.getPublicPostsData({
          departmentID: this.department.id,
          postType: type,
          filters: this.filters,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    try {
      this.filterByDepartmentURL.value = this.$route.params.parent;

      await this.getPublicDepartmentData(
        createFilters({}, [this.filterByDepartmentURL])
      );

      this.filterByDepartmentID.value = this.department.id;

      this.postTypes.forEach(async (type) => {
        this.filterByPostTypeID.value = type;
        this.filters = createFilters(this.filters, [
          this.filterByDepartmentID,
          this.filterByPostTypeID,
          this.filterPostByPublished,
          this.sortPostByPosition,
        ]);

        await this.getPosts(type);
      });
    } catch (e) {
      this.$router.push({ name: "404-page" });
    }

    runScrollPaginator("following-values-btn", () => this.filters.page++);
  },
  unmounted() {
    this.clearPublicPostsData();
    this.clearPublicDepartmentsData();
  },
};
</script>
