import { createRouter, createWebHistory } from "vue-router";
import MANAGER_ROUTES from "./manager";
import ADMIN_ROUTES from "./admin";
import PageNotFoundView from "../views/PageNotFoundView.vue";
import HomeView from "../views/public/HomeView.vue";
import AuthView from "../views/public/AuthView.vue";
import DepartmentView from "../views/public/DepartmentView.vue";
import SingleView from "../views/public/SingleView.vue";
import MemoryBookView from "../views/public/MemoryBookView.vue";
import NewsView from "../views/public/NewsView.vue";
import SearchView from "../views/public/SearchView.vue";
import CompensationView from "../views/public/CompensationView.vue";
import UserProfileView from "../views/public/user/UserProfileView.vue";

import middleware from "./middleware";

const routes = [
  // content routes
  {
    path: "/",
    name: "home-page",
    meta: {
      isMainPage: true,
    },
    component: HomeView,
  },
  {
    path: "/:parent",
    name: "department-page",
    component: DepartmentView,
  },
  {
    path: "/:parent/:url",
    name: "department-single-page",
    component: SingleView,
  },
  {
    path: "/news",
    name: "all-news-page",
    component: NewsView,
  },
  {
    path: "/search",
    name: "search-page",
    component: SearchView,
  },
  {
    path: "/news/:url",
    name: "news-page",
    meta: {
      isNewsPage: true,
    },
    component: SingleView,
  },
  {
    path: "/:parent/news/:url",
    name: "department-article-page",
    meta: {
      isNewsPage: true,
    },
    component: SingleView,
  },
  {
    path: "/page/:url",
    name: "article-page",
    component: SingleView,
  },
  {
    path: "/memory_book",
    meta: { department: "memory_book" },
    component: MemoryBookView,
  },
  {
    path: "/compensation",
    name: "compensation",
    component: CompensationView,
  },

  // public user routes
  {
    path: "/register",
    name: "public-register-page",
    component: AuthView,
  },
  {
    path: "/login",
    name: "public-login-page",
    component: AuthView,
  },
  {
    path: "/user/profile",
    name: "user-profile",
    meta: { requiresPublicAuth: true, isUser: true },
    component: UserProfileView,
  },

  // admin routes
  {
    path: "/backend/manager",
    meta: { isManager: true },
    children: MANAGER_ROUTES,
  },
  {
    path: "/backend",
    meta: { isAdmin: true },
    children: ADMIN_ROUTES,
  },

  // other
  { path: "/:pathMatch(.*)*", name: "404-page", component: PageNotFoundView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => middleware.getAccessPermission(to, next));

export default router;
