function createExcludedRoutes(routes) {
  return routes
    .map((route) => {
      if (route.children) {
        return createExcludedRoutes(route.children);
      } else {
        return route.name;
      }
    })
    .flat();
}

export default createExcludedRoutes;
