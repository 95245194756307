import { showErrorNotify } from "@/helpers/notify";

export default (instance) => {
  return {
    preSignin(payload) {
      return instance.post("api/auth/presignin", payload).catch((error) => {
        showErrorNotify(error);

        throw error;
      });
    },
    signIn(payload) {
      return instance.post("api/auth/signin", payload).catch((error) => {
        showErrorNotify(error);

        throw error;
      });
    },
    signOut() {
      return instance
        .get("api/auth/signout")
        .catch((error) => showErrorNotify(error));
    },
  };
};
