<template>
  <Dropdown
    editable
    appendTo="body"
    id="region"
    class="input-dropdown w-full"
    optionLabel="full_name"
    v-model="region"
    emptyFilterMessage="Нічого не знайдено"
    emptyMessage="Нічого не знайдено"
    emptySelectionMessage="Немає вибраного елемента"
    :showClear="showClear"
    :optionValue="optionValue"
    :class="[
      'auth-field w-full',
      !required || (required && idRegion) ? '' : 'p-invalid',
    ]"
    :options="regions"
    @change="(ev) => createSearchingField(ev)"
  />
  <label for="region" class="input-field-label">
    {{ placeholder }}
  </label>
</template>

<script>
import Dropdown from "primevue/dropdown";

export default {
  components: {
    Dropdown,
  },
  props: {
    idRegion: [String, Number],
    regionsList: Array,
    required: Boolean,
    placeholder: String,
    showClear: Boolean,
    optionValue: {
      type: String,
      default: "id",
    },
  },
  emits: ["update:idRegion"],
  data() {
    return {
      search: null,
      region: null,
    };
  },
  computed: {
    regions() {
      if (this.search && isNaN(this.search)) {
        return this.regionsList.filter((item) => {
          return (
            item.full_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }

      return this.regionsList;
    },
  },
  watch: {
    region(newID) {
      if (!isNaN(newID)) {
        this.$emit("update:idRegion", newID);
        this.search = null;
      }
    },
  },
  methods: {
    createSearchingField(ev) {
      if (Object.getPrototypeOf(ev.originalEvent) === InputEvent.prototype) {
        this.search = ev.value;
      }
    },
  },
};
</script>
