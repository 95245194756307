<template>
  <UIVisualImpairment />
  <UIAuthPanel v-if="!publicTokenIsValid" />
  <Button
    v-else
    rounded
    class="navbar-user-btn user-menu-btn"
    @click="showUserMenu = !showUserMenu"
  >
    <img src="@/assets/img/content/user.svg" alt="" srcset="" />
  </Button>
  <div
    v-if="publicTokenIsValid"
    v-show="showUserMenu"
    class="navbar-user-menu text-left"
  >
    <div class="user-info flex flex-column">
      <span class="user-fullname">{{ publicTokenData.name }}</span>
      <span class="user-post">
        {{
          publicTokenData.type_person === 1
            ? "військовий, ветеран"
            : "родич військового, ветерана"
        }}
      </span>
    </div>
    <hr />
    <div class="navbar-user-menu-list">
      <ul class="">
        <li
          v-for="item in userMenu"
          :key="item.label"
          :class="!item.label ? 'hidden' : ''"
        >
          <button
            v-if="item.isLogout"
            class="block w-full navbar-user-menu-item navbar-user-menu-item-logout"
            @click="$emit('signout')"
          >
            {{ item.label }}
          </button>
          <router-link
            v-else
            class="block w-full navbar-user-menu-item"
            :to="{ name: item.name }"
          >
            {{ item.label }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import UIAuthPanel from "./UIAuthPanel.vue";
import UIVisualImpairment from "./UIVisualImpairment.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    Button,
    UIAuthPanel,
    UIVisualImpairment,
  },
  props: {
    userMenu: Array,
  },
  emits: ["signout"],
  data() {
    return {
      showUserMenu: false,
    };
  },
  computed: {
    ...mapGetters(["publicTokenIsValid", "publicTokenData"]),
  },
  watch: {
    showUserMenu(newValue) {
      if (newValue) {
        this.userMenuListener();
      }
    },
  },
  methods: {
    userMenuListener() {
      document.addEventListener("click", (ev) => {
        if (
          !ev.target.parentElement.parentElement.classList.value
            .split(" ")
            .some(
              (el) =>
                el === "navbar-user-menu" ||
                el === "navbar-panel-wrapper" ||
                el === "navbar-user-panel"
            )
        ) {
          this.showUserMenu = null;
        }
      });
    },
  },
};
</script>
