import instance from "../instance";

import auth from "./auth";
import posts from "./posts";
import users from "./users";
import img from "./img";
import departments from "./departments";
import regions from "./regions";

const ADMIN_API_URL = process.env.VUE_APP_API_ADMIN_URL;

if (localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) !== null) {
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    process.env.VUE_APP_TOKEN_NAME
  )}`;
}

export default {
  auth: auth(ADMIN_API_URL, instance),
  posts: posts(ADMIN_API_URL, instance),
  users: users(ADMIN_API_URL, instance),
  departments: departments(ADMIN_API_URL, instance),
  img: img(ADMIN_API_URL, instance),
  regions: regions(instance),
};
