<template>
  <div class="content p-0">
    <Breadcrumb :model="breadcrumbs" />
  </div>
</template>

<script>
import Breadcrumb from "primevue/breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: {
    breadcrumbs: Array,
  },
};
</script>
