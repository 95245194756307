<template>
  <a
    class="block"
    :href="
      news.departaments.url
        ? news?.departaments?.url + `/news/${news?.url}`
        : `news/${news?.url}`
    "
  >
    <hr class="news-item-divider" />
    <div
      class="card news-item flex flex-column xl:h-full xl:justify-content-between"
    >
      <div class="card-header news-item-header flex-order-2 md:flex-order-1">
        <div class="news-item-info">
          <span class="news-item-date">{{ news?.updated_at }}</span>
        </div>
        <div class="news-item-header-text">
          <span>{{ news?.title }}</span>
        </div>
      </div>
      <div class="card-body news-item-body flex-order-1 md:flex-order-2">
        <div class="grid">
          <div
            v-if="news?.img"
            class="col-12 md:col-4 card-body-img news-item-img relative"
            :style="createBgImg(siteURL, news?.img)"
          >
            <div v-if="news?.departaments?.tag" class="department-tag news-tag">
              {{ news?.departaments?.tag }}
            </div>
          </div>
          <div v-else class="col-12 md:col-4 card-body-img news-item-img">
            <img src="@/assets/img/content/news_default.png" class="w-full" />
          </div>
          <div class="col-12 md:col-8 card-body-text news-item-body-text">
            <div
              v-html="news?.short_text ? news?.short_text : news?.title"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { createBgImg } from "@/helpers/styles";

export default {
  props: {
    news: Object,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    createBgImg,
  },
};
</script>
