<template>
  <UINavBar />
  <header
    :class="[
      'single-page-header flex align-items-center',
      isNotMainPage() ? 'border-bottom-1 border-400' : '',
    ]"
    :style="
      singlePage?.id_post_type !== NEWS_POST_TYPE
        ? createBgColor(department?.bg_color) + createColor(department?.color)
        : ''
    "
  >
    <div class="p-container">
      <div class="single-page-header-wrapper">
        <router-link
          class="single-page-header-back-link inline-block w-full"
          :to="{ path: backURL }"
        >
          <div
            class="flex justify-content-start align-items-center single-page-section-header-wrapper w-full"
          >
            <img
              class="back-link-arrow"
              :src="
                isNotMainPage() &&
                singlePage?.departaments?.url &&
                singlePage?.id_post_type !== NEWS_POST_TYPE
                  ? getImgUrl('arrow_left_white.svg')
                  : getImgUrl('arrow_back_dark.svg')
              "
              alt=""
              srcset=""
            />
            <div class="single-page-header-text">
              <span
                class="single-page-header-title"
                :style="
                  isNotMainPage() &&
                  singlePage?.departaments?.url &&
                  singlePage?.id_post_type !== NEWS_POST_TYPE
                    ? ''
                    : 'color: black;'
                "
              >
                {{ backTitle }}
              </span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </header>
  <main>
    <section class="content-section single-page-section">
      <div class="p-container relative">
        <div
          class="absolute single-page-info w-full flex justify-content-between"
        >
          <div class="view-info flex">
            <UIViewEye class="view-info-eye" />
            <span class="view-info-counter block">
              {{ singlePage?.view_count }}
            </span>
          </div>
          <div class="date-info">
            <span class="post-date">
              {{ singlePage?.updated_at }}
            </span>
          </div>
        </div>
        <div class="single-page-section-header-wrapper">
          <h1 class="section-header">
            {{ singlePage?.title }}
          </h1>
        </div>
        <div class="single-page-content">
          <Image
            v-if="singlePage?.img"
            :class="['single-page-main-img', `${$route.params.parent}-img`]"
            :src="siteURL + singlePage?.img"
            preview
          />

          <div
            v-if="singlePage?.short_text"
            class="single-page-text"
            v-html="singlePage?.short_text"
          ></div>
          <div class="single-page-text" v-html="singlePage?.text"></div>
        </div>
      </div>
    </section>
  </main>

  <FooterSection />
</template>

<script>
import FooterSection from "@/components/public/content/FooterSection.vue";
import UINavBar from "@/components/public/ui/UINavBar.vue";
import UIViewEye from "@/components/public/ui/UIViewEye.vue";
import Image from "primevue/image";

import filters from "@/config/filters.yml";

import types from "@/config/types.yml";

import { mapActions, mapGetters, mapState } from "vuex";
import { createFilters } from "@/helpers/filters";
import { createBgColor, createColor } from "@/helpers/styles";

const NEWS_POST_TYPE = types.post.news;
const PAGE_POST_TYPE = types.post.page;

export default {
  name: "SingleView",
  components: {
    UINavBar,
    FooterSection,
    UIViewEye,
    Image,
  },
  data() {
    return {
      NEWS_POST_TYPE,
      PAGE_POST_TYPE,
      siteURL: process.env.VUE_APP_API_URL,
      filterByDepartmentURL: {
        field: filters.departaments.filterByURL,
        value: null,
      },
      filterByDepartmentID: {
        field: filters.posts.filterByDepartmentID,
        value: null,
      },
      filterByPostTypeID: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      filterByPostURL: {
        field: filters.posts.filterByURL,
        value: null,
      },
      filterPostByPublished: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      filters: {},

      backURL: "",
      backTitle: "",
    };
  },
  computed: {
    ...mapState({
      department: (state) => state.public_departments.department,
    }),

    ...mapGetters(["singlePage"]),
  },
  methods: {
    ...mapActions(["getPublicDepartmentData", "getPublicPostsData"]),

    createBgColor,
    createColor,
    isNotMainPage() {
      return !this.$route.meta.isMainPage;
    },
    getImgUrl(img) {
      return require("../../assets/img/content/" + img);
    },
    createBackURL() {
      if (
        this.singlePage &&
        this.$route.params.parent &&
        this.singlePage.id_post_type !== NEWS_POST_TYPE
      ) {
        this.backURL = "/" + this.department?.url;
        this.backTitle = this.department?.title;
      } else if (
        this.singlePage &&
        this.singlePage.id_post_type === NEWS_POST_TYPE
      ) {
        this.backURL = "/news";
        this.backTitle = "Всі новини";
      } else {
        this.backURL = "/";
        this.backTitle = "Повернутися на головну";
      }
    },
    goTo404() {
      this.$router.push({ name: "404-page" });
    },
  },

  async mounted() {
    try {
      this.filterByDepartmentID.value = this.isNotMainPage() ? null : 0;
      if (this.isNotMainPage() && this.$route.params.parent) {
        this.filterByDepartmentURL.value = this.$route.params.parent;

        await this.getPublicDepartmentData(
          createFilters({}, [this.filterByDepartmentURL])
        );

        this.filterByDepartmentID.value = this.department.id;
      }

      this.filterByPostURL.value = this.$route.params.url;
      this.filterByPostTypeID.value = this.$route.meta.isNewsPage
        ? NEWS_POST_TYPE
        : PAGE_POST_TYPE;

      this.filters = createFilters(this.filters, [
        this.filterByDepartmentID,
        this.filterByPostTypeID,
        this.filterByPostURL,
        this.filterPostByPublished,
      ]);

      await this.getPublicPostsData({
        departmentID: this.filterByDepartmentID.value,
        postType: PAGE_POST_TYPE,
        filters: this.filters,
      });

      if (!this.singlePage) {
        this.goTo404();
      }

      this.createBackURL();
    } catch (e) {
      this.goTo404();
    }
  },
};
</script>
