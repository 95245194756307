function createBgColor(bgColor) {
  return bgColor && bgColor !== "" ? `background-color: ${bgColor};` : "";
}
function createColor(textColor) {
  return textColor && textColor !== "" ? `color: ${textColor};` : "";
}
function createBgImg(siteUrl, bgIMG) {
  return bgIMG && bgIMG !== ""
    ? `background-image: url(${siteUrl + bgIMG});`
    : "";
}

export { createBgColor, createColor, createBgImg };
