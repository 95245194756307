<template>
  <div id="notfound">
    <div class="notfound">
      <div class="notfound-404">
        <h3>Ой! Сторінку не знайдено</h3>
        <h1><span>4</span><span>0</span><span>4</span></h1>
      </div>
      <h2>ми щиро вибачаємось, але сторінку не знайдено</h2>
      <Button
        outlined
        rounded
        severity="secondary"
        label="Перейти на головну"
        icon="pi pi-arrow-circle-right"
        iconPos="right"
        @click="$router.push('/')"
      />
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";

export default {
  components: {
    Button,
  },
};
</script>
