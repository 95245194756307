import { showErrorNotify, showUserNotify } from "@/helpers/notify";

export default (adminURL, instance) => {
  return {
    getDepartmentsData(payload) {
      return instance
        .post(adminURL + "departament/all", payload)
        .catch((error) => showErrorNotify(error));
    },
    updateDepartment(id, payload) {
      return instance
        .put(adminURL + `departament/${id}`, payload)
        .then(() => showUserNotify("success"))
        .catch((error) => {
          showErrorNotify(error);

          throw error;
        });
    },
    createDepartment(payload) {
      return instance
        .post(adminURL + `departament/create`, payload)
        .then(() => showUserNotify("success"))
        .catch((error) => {
          showErrorNotify(error);

          throw error;
        });
    },
  };
};
