<template>
  <div class="sidebar">
    <div
      class="sidebar-item-wrapper"
      v-for="item in sidebarItems"
      :key="item.id"
    >
      <div
        :class="[
          'block sidebar-item',
          item?.text ? '' : 'flex align-items-center',
          isLink(item?.url) ? 'link' : '',
          parseOptions(item?.options)?.className,
        ]"
        :style="createBgColor(item?.bg_color)"
      >
        <a
          v-if="isLink(item?.url)"
          class="block w-full h-full sidebar-item-link"
          :href="isLink(item?.url) ? item?.url : ''"
        >
          <SideBarItem :style="createColor(item?.text_color)" :item="item" />
        </a>
        <div v-else>
          <SideBarItem :style="createColor(item?.text_color)" :item="item" />
        </div>
      </div>
      <div class="sidebar-item-description" v-html="item?.short_text"></div>
    </div>
  </div>
</template>

<script>
import SideBarItem from "./SideBarItem.vue";

import { createBgColor, createColor } from "@/helpers/styles";

export default {
  components: {
    SideBarItem,
  },
  props: {
    sidebarItems: Array,
  },
  methods: {
    createBgColor,
    createColor,

    isLink(url) {
      return url && url !== "";
    },
    parseOptions(options) {
      let sidebarOptions = null;
      try {
        sidebarOptions = JSON.parse(options);
      } catch (error) {
        sidebarOptions = null;
      }

      return sidebarOptions;
    },
  },
};
</script>
