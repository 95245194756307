<template>
  <button
    class="personal-info-edit-btn w-full md:w-max flex-order-2 lg:flex-order-1 md:m-auto lg:absolute"
    :disabled="disabled"
    @click="onClick()"
  >
    {{ show ? "Зберегти" : "Редагувати" }}
  </button>
</template>

<script>
export default {
  props: {
    show: Boolean,
    disabled: Boolean,
  },
  emits: ["update", "update:show"],
  methods: {
    onClick() {
      if (this.show) {
        this.$emit("update");
      }

      this.$emit("update:show", !this.show);
    },
  },
};
</script>
