import { showErrorNotify } from "@/helpers/notify";

export default (instance) => {
  return {
    getOtgByRegion(id) {
      return instance.get(`/api/regions/list/${id}/otg`).catch((error) => {
        showErrorNotify(error);

        throw error;
      });
    },
  };
};
