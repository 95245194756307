import { showErrorNotify } from "@/helpers/notify";

export default (instance) => {
  return {
    registrationProfile(payload) {
      return instance.post("api/user/registration", payload).catch((error) => {
        showErrorNotify(error);

        throw error;
      });
    },
    confirmRegistrationPhone(payload) {
      return instance
        .post("api/user/confirmphone", payload)
        .catch((error) => showErrorNotify(error));
    },
  };
};
