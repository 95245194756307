<template>
  <BreadcrumbsList :breadcrumbs="breadcrumbs" />

  <div class="content mt-3">
    <DepartmentsTable
      :title="'Органи влади'"
      :filters="filters"
      :departments="departments"
      @getItemsByFilter="
        async (filter) => {
          filters = createFilters(filters, [filter, sortByPosition]);
          await getDepartmentsData(filters);
        }
      "
      @clearFilters="
        async () => {
          filters = clearFiltersList();
          await getDepartmentsData(filters);
        }
      "
    />
  </div>
  <div class="content mt-3 p-2">
    <TablePaginator
      :rows="departmentsData.count"
      :totalRows="departmentsData.total_rows"
      @getItemsByPage="
        async (event) => {
          filters = createFilters(changePageFilter(filters, event), [
            this.sortByPosition,
          ]);

          await getDepartmentsData(filters);
        }
      "
    />
  </div>
</template>

<script>
import DepartmentsTable from "@/components/admin/departments/DepartmentsTable.vue";
import BreadcrumbsList from "@/components/admin/ui/BreadcrumbsList.vue";
import TablePaginator from "@/components/admin/ui/TablePaginator.vue";

import filters from "@/config/filters.yml";

import { mapActions, mapState } from "vuex";
import {
  createFilters,
  clearFiltersList,
  changePageFilter,
} from "@/helpers/filters";

export default {
  name: "DepartmentsView",
  components: {
    DepartmentsTable,
    BreadcrumbsList,
    TablePaginator,
  },
  data() {
    return {
      breadcrumbs: [{ label: "Органи влади" }],
      search: "",
      filters: {
        page: 1,
        pageSize: 20,
      },
      sortByPosition: {
        field: filters.departaments.sortByPosition,
        sort: "ASC",
      },
    };
  },
  computed: {
    ...mapState({
      departmentsData: (state) => state.departments.departmentsData,
    }),

    departments() {
      if (this.departmentsData.rows) {
        return this.departmentsData.rows.filter((item) => {
          return item.title.toLowerCase().indexOf(this.search) > -1;
        });
      }

      return [];
    },
  },
  methods: {
    ...mapActions(["getDepartmentsData", "clearDepartmentsData"]),

    createFilters,
    clearFiltersList,
    changePageFilter,
  },
  async mounted() {
    this.filters = createFilters(this.filters, [this.sortByPosition]);
    await this.getDepartmentsData(this.filters);
  },
  unmounted() {
    this.clearDepartmentsData();
  },
};
</script>
