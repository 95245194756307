<template>
  <TopMenuBar v-model:visible="showSideBar" />

  <div class="workspace">
    <div class="grid">
      <div :class="showSideBar ? 'sm:col-3 xl:col-2' : 'd-none'">
        <SideMenuBar
          v-model:showSideBar="showSideBar"
          :sideBarMenuItems="
            $route.meta.isAdmin ? adminMenuItems : managerMenuItems
          "
          :isMobile="isMobile"
        />
      </div>
      <div :class="showSideBar ? 'sm:col-9 xl:col-10' : 'sm:col-12'">
        <div class="worckspace-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog class="w-9 md:w-6 xl:w-3" />
</template>

<script>
import TopMenuBar from "@/components/admin/ui/TopMenuBar.vue";
import SideMenuBar from "@/components/admin/ui/SideMenuBar.vue";
import ConfirmDialog from "primevue/confirmdialog";

export default {
  name: "WorkspasePanelView",
  components: {
    TopMenuBar,
    SideMenuBar,
    ConfirmDialog,
  },
  data() {
    return {
      windowWidth: null,
      showSideBar: true,
      isMobile: false,
      adminMenuItems: [
        {
          label: "Публікації",
          icon: "pi pi-fw pi-book",
          items: [
            {
              label: "Створити нову",
              icon: "pi pi-fw pi-plus",
              command: () => {
                this.$router.push({ name: "admin-create-post" }).then(() => {
                  this.$router.go();
                });
              },
            },
            {
              label: "Переглянути всі",
              icon: "pi pi-fw pi-list",
              to: { name: "admin-posts" },
            },
          ],
        },
        {
          label: "Розділи",
          icon: "pi pi-fw pi-book",
          to: { name: "admin-departments" },
        },
        {
          label: "Менеджери",
          icon: "pi pi-fw pi-book",
          to: { name: "admin-managers" },
        },
      ],
      managerMenuItems: [
        {
          label: "Публікації",
          icon: "pi pi-fw pi-book",
          items: [
            {
              label: "Створити нову",
              icon: "pi pi-fw pi-plus",
              command: () => {
                this.$router.push({ name: "create-post" }).then(() => {
                  this.$router.go();
                });
              },
            },
            {
              label: "Переглянути всі",
              icon: "pi pi-fw pi-list",
              to: { name: "posts" },
            },
          ],
        },
      ],
    };
  },
  async mounted() {
    this.windowWidth = window.innerWidth;

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 768) {
        this.showSideBar = false;
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.showSideBar = true;
      }
    });
  },
};
</script>
