/* eslint-disable no-useless-catch */
import api from "@/api/administration/index";

const DEFAULT_DATA = {
  count: 0,
  pages: 0,
  rows: [],
  total_rows: 0,
};

export default {
  state: {
    usersData: DEFAULT_DATA,
  },
  mutations: {
    setUsersData(state, payload) {
      state.usersData = payload;
    },
    setDefaultUsersData(state) {
      state.usersData = DEFAULT_DATA;
    },
  },
  actions: {
    async getUsersData({ commit }, payload) {
      try {
        const usersData = (await api.users.getUsersData(payload)).data;

        commit("setUsersData", usersData);
      } catch (e) {
        throw e;
      }
    },
    async createUserData({ dispatch }, payload) {
      try {
        await api.users.createUser(payload);

        dispatch("getUsersData", {});
      } catch (e) {
        throw e;
      }
    },
    async updateUserData({ dispatch }, payload) {
      try {
        await api.users.updateUser(payload.id, payload.user_data);

        dispatch("getUsersData", {});
      } catch (e) {
        throw e;
      }
    },
    async removeUserData({ dispatch }, payload) {
      try {
        await api.users.removeUser(payload);

        dispatch("getUsersData", {});
      } catch (e) {
        throw e;
      }
    },
    clearUsersData({ commit }) {
      commit("setDefaultUsersData");
    },
  },
};
