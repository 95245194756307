import { showErrorNotify, showUserNotify } from "@/helpers/notify";

function setPublicToken(instance) {
  if (localStorage.getItem(process.env.VUE_APP_PUBLIC_TOKEN_NAME) !== null) {
    instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      process.env.VUE_APP_PUBLIC_TOKEN_NAME
    )}`;
  }
}

export default (publicURL, instance) => {
  return {
    updateUserProfile(payload) {
      setPublicToken(instance);

      return instance
        .post(`${publicURL}update`, payload)
        .then(showUserNotify("success"))
        .catch((error) => showErrorNotify(error));
    },
    getProfile() {
      setPublicToken(instance);

      return instance
        .get(`${publicURL}profile`)
        .catch((error) => showErrorNotify(error));
    },
  };
};
