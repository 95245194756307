import { showErrorNotify } from "@/helpers/notify";

export default (instance) => {
  return {
    getRegions() {
      return instance
        .get("api/regions")
        .catch((error) => showErrorNotify(error));
    },
    getCherkassyRegions() {
      return instance
        .get("api/regions/cherkassy")
        .catch((error) => showErrorNotify(error));
    },
  };
};
