import { createStore } from "vuex";

import admin_auth from "./administration/auth";
import posts from "./administration/posts";
import users from "./administration/users";
import departments from "./administration/departments";
import regions from "./administration/regions";

import public_posts from "./public/posts";
import public_departments from "./public/departments";
import public_authentication from "./public/authentication";
import public_user from "./public/user";
import public_regions from "./public/regions";
import public_search from "./public/searching";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    admin_auth,
    posts,
    users,
    departments,
    regions,
    public_posts,
    public_departments,
    public_authentication,
    public_user,
    public_regions,
    public_search,
  },
});
