<template>
  <div class="mt-3 py-2">
    <div class="grid">
      <div class="col-6">
        <div class="p-fluid grid">
          <div class="col-12">
            <span class="p-float-label mb-3">
              <InputText
                required
                id="title"
                type="text"
                v-model="department.title"
                :class="department.title ? '' : 'p-invalid'"
              />
              <label for="title">Назва</label>
            </span>
          </div>
          <div class="col-12">
            <span class="p-float-label">
              <Textarea
                id="short_text"
                type="text"
                rows="5"
                v-model="department.short_text"
              />
              <label for="text">Короткий опис</label>
            </span>
          </div>
          <div class="col-12">
            <span class="p-float-label mb-3">
              <Textarea
                id="text"
                type="text"
                rows="5"
                v-model="department.text"
              />
              <label for="text">Повний опис</label>
            </span>
          </div>
          <div class="col-12 md:col-4">
            <span class="p-float-label">
              <InputText id="url" type="text" v-model="department.url" />
              <label for="url">URL</label>
            </span>
          </div>
          <div class="col-12 md:col-4">
            <span class="p-float-label">
              <InputText
                id="position"
                type="number"
                v-model.number="department.position"
              />
              <label for="position">Позиція</label>
            </span>
          </div>
          <div class="col-12 md:col-4">
            <span class="p-float-label">
              <InputText id="tag" type="text" v-model="department.tag" />
              <label for="position">Тег</label>
            </span>
          </div>
          <div class="col-12 md:col-6">
            <label>Адмінпанель</label>
            <Dropdown
              required
              optionValue="id"
              optionLabel="name"
              placeholder="Тип адмін панелі"
              v-model="department.is_admin"
              :options="adminTypes"
            />
          </div>
          <div class="col-12 md:col-6">
            <label>Тип</label>
            <Dropdown
              required
              optionValue="id"
              optionLabel="name"
              placeholder="Тип"
              v-model="department.type"
              :options="departmentTypes"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div v-if="department.type === SERVICE_TYPE">
          <UploadImgPanel
            :title="'Логотип картки'"
            :id="'logo_img'"
            :img="logoImg"
            :imgSrc="department.logo"
            @update:img="logoImg = $event"
            @update:imgSrc="department.logo = $event"
          />
          <hr />
        </div>
        <div>
          <UploadImgPanel
            :title="'Фонове зображення'"
            :id="'bg_img'"
            :img="bgImg"
            :imgSrc="department.bg_img"
            @update:img="bgImg = $event"
            @update:imgSrc="department.bg_img = $event"
          />
          <hr />
        </div>
        <div>
          <UploadImgPanel
            v-if="department.type === DEPARTMENT_TYPE"
            :title="'Логотип'"
            :id="'header_img'"
            :img="headerImg"
            :imgSrc="department.img"
            @update:img="headerImg = $event"
            @update:imgSrc="department.img = $event"
          />
          <hr />
        </div>

        <div class="flex align-items-center justify-content-evenly">
          <h4>
            {{
              changeColors
                ? "Встановити кольори за замовчуванням"
                : "Редагувати кольори"
            }}
          </h4>
          <InputSwitch
            aria-labelledby="switch2"
            v-model="changeColors"
            @click="
              () => {
                if (department.text_color || department.bg_color) {
                  department.text_color = department.bg_color = null;
                }
              }
            "
          />
        </div>
        <div v-if="changeColors" class="mt-2" :key="colorsPanelKey">
          <ChangeColorsPanel
            :title="'Фоновий колір'"
            :color="department.bg_color"
            @update:color="department.bg_color = $event"
          />
          <hr class="my-3" />
          <ChangeColorsPanel
            :title="'Колір тексту'"
            :color="department.text_color"
            @update:color="department.text_color = $event"
          />
        </div>
      </div>
      <div class="grid">
        <div class="col-12 flex justify-content-end">
          <Button
            label="Зберегти"
            v-tooltip.bottom="'Зберегти'"
            :disabled="
              !department.title ||
              !department.url ||
              department.is_admin == null
            "
            @click="saveDepartment(department)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import ChangeColorsPanel from "@/components/admin/ui/ChangeColorsPanel.vue";
import UploadImgPanel from "@/components/admin/ui/UploadImgPanel.vue";
import InputSwitch from "primevue/inputswitch";

import "vue3-colorpicker/style.css";

import types from "@/config/types.yml";

import { mapActions } from "vuex";

const SERVICE_TYPE = types.department.type.service.id;
const DEPARTMENT_TYPE = types.department.type.department.id;

export default {
  inject: ["dialogRef"],
  components: {
    InputText,
    Dropdown,
    Button,
    Textarea,
    ChangeColorsPanel,
    UploadImgPanel,
    InputSwitch,
  },
  data() {
    return {
      SERVICE_TYPE,
      DEPARTMENT_TYPE,
      changeColors: false,
      department: {
        title: null,
        url: null,
        short_text: null,
        tag: null,
        text: null,
        img: null,
        logo: null,
        bg_img: null,
        bg_color: null,
        text_color: null,
        is_admin: null,
        type: null,
        position: 1,
      },
      bgImg: null,
      logoImg: null,
      headerImg: null,

      siteURL: process.env.VUE_APP_API_URL,
      colorsPanelKey: 0,

      departmentTypes: [],
      adminTypes: [],
    };
  },
  watch: {
    "department.bg_color"() {
      this.colorsPanelKey++;
    },
  },
  methods: {
    ...mapActions(["createDepartment", "updateDepartmentData"]),

    saveDepartment(department) {
      let departmentData = new FormData();

      departmentData.append("data", JSON.stringify(department));
      departmentData.append("img", this.headerImg);
      departmentData.append("logo", this.logoImg);
      departmentData.append("bg_img", this.bgImg);

      try {
        if (department.id) {
          this.updateDepartmentData({
            id: department.id,
            department_data: departmentData,
            filters: this.dialogRef.data.filters,
          });
        } else {
          this.createDepartment({
            department_data: departmentData,
            filters: this.dialogRef.data.filters,
          });
        }

        this.dialogRef.close();
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.departmentTypes = Object.keys(types.department.type).map(
      (el) => types.department.type[el]
    );

    this.adminTypes = Object.keys(types.department.admin).map(
      (el) => types.department.admin[el]
    );

    if (this.dialogRef.data.department) {
      this.department = { ...this.dialogRef.data.department };

      this.changeColors =
        this.department.bg_color || this.department.text_color;
    }
  },
};
</script>
