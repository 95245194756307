<template>
  <div class="post-preview content-view">
    <div class="flex justify-content-center">
      <img
        class="post-preview-img"
        :src="params?.post.img ? siteURL + params?.post.img : ''"
        :style="{ width: '35%' }"
      />
    </div>
    <Divider />
    <h4>Короткий опис</h4>
    <div
      class="post-preview-description"
      v-html="params?.post.short_text"
    ></div>
    <Divider />
    <h4>Основний текст</h4>
    <div class="post-preview-text" v-html="params?.post.text"></div>
  </div>
</template>

<script>
import Divider from "primevue/divider";

export default {
  inject: ["dialogRef"],
  components: {
    Divider,
  },
  props: {},
  data() {
    return { params: null, siteURL: process.env.VUE_APP_API_URL };
  },
  mounted() {
    this.params = this.dialogRef.data;
  },
};
</script>
