/* eslint-disable no-useless-catch */
import api from "@/api/administration/index";

const DEFAULT_DATA = {
  count: 0,
  pages: 0,
  rows: [],
  total_rows: 0,
};

export default {
  state: {
    departmentsData: DEFAULT_DATA,
  },
  mutations: {
    setDepartmentsData(state, payload) {
      state.departmentsData = payload;
    },
    setDefaultDepartmentsData(state) {
      state.departmentsData = DEFAULT_DATA;
    },
  },
  actions: {
    async getDepartmentsData({ commit }, payload) {
      try {
        const departmentsData = (
          await api.departments.getDepartmentsData(payload)
        ).data;

        commit("setDepartmentsData", departmentsData);
      } catch (e) {
        throw e;
      }
    },
    async updateDepartmentData({ dispatch }, payload) {
      try {
        await api.departments.updateDepartment(
          payload.id,
          payload.department_data
        );

        dispatch("getDepartmentsData", payload.filters);
      } catch (e) {
        throw e;
      }
    },
    async createDepartment({ dispatch }, payload) {
      try {
        await api.departments.createDepartment(payload.department_data);

        dispatch("getDepartmentsData", payload.filters);
      } catch (e) {
        throw e;
      }
    },
    clearDepartmentsData({ commit }) {
      commit("setDefaultDepartmentsData");
    },
  },
  getters: {
    pagesList: (state) =>
      [{ id: 0, title: "Головна", url: "" }].concat(state.departmentsData.rows),
    departmentByField: (state) => (payload) => {
      if (state.departmentsData.rows && state.departmentsData.rows.length > 0) {
        return state.departmentsData.rows.find(
          (el) => el[payload.field] === payload.value
        );
      }
      return null;
    },
  },
};
