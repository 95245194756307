<template>
  <form action="" @submit.prevent="search()">
    <div
      class="input-wrapper search-wrapper p-float-label p-input-icon-right w-full"
    >
      <InputText
        type="text"
        id="search"
        v-model="searchQuery"
        :class="['search-field', searchError ? 'p-invalid' : '']"
      />
      <i class="pi pi-search pl-4 pr-2" @click="search()" />
      <label
        for="search"
        :class="['search-field-label', searchError ? 'p-invalid' : '']"
      >
        {{
          searchError ? "Мінімальна кількість символів - 3" : "Пошук по сайту"
        }}
      </label>
    </div>
  </form>
</template>

<script>
import InputText from "primevue/inputtext";
import { mapActions } from "vuex";

export default {
  components: {
    InputText,
  },
  props: {
    showMobileMenu: Boolean,
  },
  emits: ["showMobileMenu"],
  data() {
    return {
      searchError: false,
      searchQuery: null,
    };
  },
  watch: {
    searchQuery() {
      if (this.searchQuery) {
        this.searchError = this.searchQuery.length >= 3 ? false : true;
      }
    },
  },
  methods: {
    ...mapActions(["getItemsBySearchQuery"]),

    async search() {
      if (this.searchQuery?.length >= 3) {
        this.searchQuery = this.searchQuery.trim();

        if (this.$route.name === "search-page") {
          await this.getItemsBySearchQuery(this.searchQuery);

          this.$router.replace({
            query: { search: this.searchQuery },
          });
        } else {
          this.$router.push({
            name: "search-page",
            query: { search: this.searchQuery },
          });
        }

        if (this.showMobileMenu) {
          this.$emit("showMobileMenu", false);
        }

        this.searchQuery = null;
      }
    },
  },
};
</script>
