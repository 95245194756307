<template>
  <div class="flex flex-column sm:flex-row sm:justify-content-center sm:gap-3">
    <router-link :to="{ name: 'public-login-page' }">
      <button class="navbar-user-btn login-btn">Увійти</button>
    </router-link>
    <router-link :to="{ name: 'public-register-page' }">
      <button class="navbar-user-btn register-btn">Зареєструватись</button>
    </router-link>
  </div>
</template>
